.placeHolder {
	width: 100%;
	position: relative;
}

.container {
	display: flex;
	flex-direction: column;
	max-height: 200px;
	position: absolute;
	padding: 0 10px;
	width: 100%;
	overflow-y: auto;
	background-color: white;
	border: 1px solid #eee;
	bottom: 0;
	border-radius: 10px;
	margin-bottom: 5px;
	box-shadow: -1px -11px 16px -7px rgba(0, 0, 0, 0.1);
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #ff5b5b;
	}
}

.userContainer {
	display: flex;
	border-bottom: 1px solid #eee;
	align-items: center;
	padding: 10px;
	p {
		margin: 0 0 0 10px;
		font-size: 0.9rem;
		color: #777;
	}
}
