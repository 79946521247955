.bottomNav {
	background: #030413;
	display: flex;
	align-items: center;
	padding: 10px 5px;

	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 10000;
}

.nav {
	flex: 1;
	display: flex;
	flex-direction: column;
	/* align-content: center; */
	align-items: center;
	justify-content: flex-end;
}

.navText {
	font-size: 10px;
	color: #7f89be;
	margin: 5px 0;
}

.activeNavText {
	font-size: 10px;
	color: #6e88fc;
	margin: 5px 0;
	border-radius: 50%;
}
