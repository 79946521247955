.dialogDark {
	min-width: 500px;
	position: relative;
	// background: var(--dark-background) !important;
	background: #0d0f22 !important;
	color: rgb(217, 222, 244) !important;
}

.dialogLg {
	min-width: 500px;
	position: relative;
	background-color: #eee;
}

.header {
	display: flex;
	justify-content: space-between;
	padding: 1rem 2rem;
}

.closeButton {
	position: absolute;
	right: 15px;
}

.title {
	// padding: 3rem 0 2rem 2.5rem;
	color: var(--heading-color2);
	h2 {
		font-weight: normal !important;
		font-size: 1.25rem !important;
	}
}

.content {
	display: flex;
	flex-direction: column;
	// padding: 0 1.7rem;
	padding: 0rem 2rem;
}

.buttonContainer {
	padding-top: 2rem;
	padding-bottom: 3rem;
	display: flex;
	justify-content: space-between;
	.button {
		text-transform: capitalize;
		font-size: 1rem;
		font-weight: 400;
	}
}

@media screen and (max-width: 768px) {
	.dialogDark {
		min-width: 90%;
		position: relative;
		background: #0d0f22 !important;
		color: rgb(217, 222, 244) !important;
	}

	.dialogLg {
		min-width: 90%;
		position: relative;
		background-color: #eee;
	}

	.buttonContainer {
		flex-direction: column-reverse;
		padding: 30px 0;
	}

	.header {
		padding: 1rem 0;
	}

	.content {
		padding: 0rem 0rem;
	}
}
