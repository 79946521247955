@import "../../../Themes/ThemeSelector.scss";

.item {
	@include themed() {
		background: t(dark-background);
		color: t(heading-color2);
	}
}

.myGrp {
	@include themed() {
		color: t(heading-color2);
		border: 1px solid t(light-blue);
	}
}
