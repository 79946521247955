.popoverBtn2 {
	margin: 0.5rem;
	font-size: 0.95rem;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;

	color: #312e2e;
	outline: none;
	border: none;
	background: transparent;
	cursor: pointer;
}

.popoverBtn {
	margin: 0.5rem;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 1.05rem;
	/* line-height: 1rem; */
	color: #434343;
	outline: none;
	border: none;
	background: transparent;
	cursor: pointer;
}

.popoverBtn:hover {
	color: #ff5b5b;
}

.container {
	padding: 1.5rem 2rem 1rem 2rem;
	display: flex;
	border-radius: 0px;
	max-width: 25rem;
	flex-direction: column;
}
.name {
	font-weight: 500;
}
.crossIcon {
	margin-top: 1.2rem;
	margin-right: 1.8rem;
	position: absolute;
	right: 0;
	cursor: pointer;
}

.title {
	text-align: center;
	margin-bottom: 1.99rem;
	font-size: 1.2rem;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
	color: #434343;
}

.body {
	margin-bottom: 1.5rem;
	font-size: 1rem;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;

	line-height: 1.25rem;
	/* or 21px */

	text-align: center;

	color: #312e2e;
}

.buttonBox {
	display: flex;
	/* margin-left: auto; */
	flex-direction: row;
	justify-content: center;
}

.agree {
	margin-left: 1rem;
	background-color: #ff5b5b;
	color: white;
	border: 0;
	border-radius: 6px;
	font-size: 1rem;
	width: 70%;
	padding: 0.4rem 0.7rem;
	font-family: Roboto;
}

.disagree {
	width: 70%;
	/* margin-left: 1rem; */
	background-color: #ffffff;
	color: #ff5b5b;
	border: 1px solid #ff5b5b;
	border-radius: 6px;
	font-size: 1rem;
	padding: 0.4rem 0.7rem;
	font-family: Roboto;
}
