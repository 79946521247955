.wrapper {
	display: flex;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins";
}

.wrapper::before {
	content: "";
	display: inline-block;
	/* height: 100%; */
	vertical-align: middle;
}
.container {
	margin: auto;
	width: 80rem;
	height: 90.9vh;
	/* overflow: hidden; */

	overflow-y: auto;
	padding-top: 0.1rem;
	display: flex;
	flex-direction: column;
}

.container::-webkit-scrollbar {
	display: none;
}

.header {
	padding: 0.5rem 1rem;
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
}

.header h2 {
	margin: 2px 0 0 0rem;
	flex: 1;
	font-size: 1.25rem;
	font-weight: 500;
}
.searchContainer {
	margin: 0.7rem 0.7rem 2rem 0.7rem;
	border: 1px solid #7f89be;
	max-width: 90%;
	padding: 10px;
	border-radius: 6px;
	display: flex;
}

.input {
	margin-left: 0.6em;
	border: 0;
	font-size: 0.9rem;
	font-weight: 400;
	flex: 1;
	color: #7f89be;
	background: transparent;
	padding: 5px 0;
}

.input::placeholder {
	color: #7f89be;
}

.heading {
	margin: 2.5rem;
	display: flex;
}

.heading h1 {
	font-size: 3rem;
	font-weight: 600;
	cursor: pointer;
	font-family: "Poppins";
}

.cards {
	justify-content: flex-start;
	margin: 0 auto 0 0;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.section {
	justify-content: flex-start;
	margin: 0.7rem 1rem;
	display: flex;
	width: 100%;
}

.time {
	font-weight: 400;
	font-size: 1.25rem;
}

.default {
	margin: 3rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Poppins";
}

.loader {
	margin: 0 auto;
	margin-bottom: 30px;
	width: 30%;
	justify-self: center;
	padding: 1rem 3rem;
	display: flex;
	justify-content: center !important;
	border-radius: 5px;
	border: 1px solid #eee;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 768px) {
	.wrapper {
		height: 100%;
	}
	.container {
		width: 100%;
		height: 100%;
	}
	.section {
		margin: 0;
	}
	.cards {
		/* margin: 0 auto 0 0.2rem; */
		padding: 1rem 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.time {
		margin: 8px 15px;
	}
}
