.container {
	width: 80%;
	display: flex;
	position: relative;
	z-index: 1;
	font-family: Roboto;
}

.rootDark {
	background: #121528 !important;
}

.rootLg {
	background: #fff !important;
}

.mobChatNav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* background: #030413; */
	padding: 10px 15px;
	color: #d9def4;
}

.loader {
	background-color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
	border-radius: 50%;
	height: 40px;
	width: 40px;
	padding: 10px;
}

.loaderContainer {
	top: 8rem;
	display: flex;
	justify-content: center;
	position: absolute;
	width: 90%;
}

.chatBox {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 1rem 3rem;
	transition: all 0.3s ease-in-out;
}

.messageCont {
	transition: 500ms;
}
.highlighted {
	background-color: rgb(248, 159, 159, 0.4);
	z-index: -1;
	border-radius: 10px;
	border: rgb(248, 159, 159);
}

.chatBoxHalf {
	width: 72%;
	display: flex;
	flex-direction: column;
	margin: 1rem;
	position: relative;
	transition: all 0.001s ease-in;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.chatButton {
	display: none;
}

.textIcon {
	color: #d9def4;
	font-size: 1.7rem;
	margin-left: -6px;
}

/* .textIcon p {
  margin: 0;
  margin-left: 1rem;
  color: #555;
} */

.title {
	/* position: relative;
  padding: 0.5rem 1rem;
  background-color: #fbfbfb;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  cursor: pointer; */
	width: 100%;
	position: relative;
	background-color: transparent;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	/* cursor: pointer; */
	display: flex;
	align-items: center;
	padding: 0 0.7rem;
	padding-left: 1.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.titletop_breakline {
	margin-left: 0 !important;
	width: 100%;
	border: 1px solid #2e2e48;
}

.content {
	font-size: 1.25rem;
	margin: 0;
	margin-left: 0.5rem;
	flex: 1;

	font-weight: 500;
	color: #d9def4;
	font-family: "Poppins";
	display: flex;
	align-items: center;
	margin-left: 15px;
}

.mainChatBoxDk,
.mainChatBoxLG {
	display: flex;
	flex-direction: column-reverse;
	padding: 1rem;
	overflow: auto;
	height: 100%;
	scrollbar-width: none;
}

.mainChatBox::-webkit-scrollbar {
	width: 0;
	background-color: transparent;
}
.mainChatBoxLG::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.mainChatBoxLG::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}
.mainChatBoxDk::-webkit-scrollbar-thumb {
	background: #d9def4 !important;
}
.mainChatBoxDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}

.typing {
	color: #888;
	font-size: 0.9rem;
	position: absolute;
	bottom: 4rem;
}

.inputBox {
	display: flex;
	width: 100%;
	margin-top: 1rem;
	bottom: 0;
}

.message {
	width: 94%;
	padding: 0.7rem 1rem;
	border: 0;
	height: 3rem;
	resize: none;
	border-radius: 6px;
	background-color: white;
	color: #555;
	box-shadow: 0px 2px 20px #00000026;
}

.joinConv {
	width: 94%;
	padding: 0.7rem 1rem;
	border: 0;
	color: #ff5b5b;
	background-color: white;
	border-radius: 6px;
	box-shadow: 0px 2px 20px #00000026;
}

.lghr {
	border: 1px solid #4a517e;
}

.darkhr {
	border: 1px solid #7f89be;
}

.lgtext {
	color: #0d0f22 !important;
}

.darktext {
	color: #d9def4 !important;
}

.thoughtsTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #7f89be;
	margin: 15px 15px;
	border-radius: 9px;
}

.activeMobTab {
	background: linear-gradient(92.12deg, #00c6ff, #0072ff);
	/* background: #4a517e; */
	color: #fff;
	border-radius: 9px;
	padding: 8px;
	width: 100%;
	text-align: center;
}

.mobtab {
	padding: 8px;
	width: 100%;
	text-align: center;
}

@media only screen and (max-width: 600px) {
	.container {
		width: 100%;
	}

	.chatBox {
		padding: 1rem;
	}

	.chatBoxHalf {
		padding: 1rem;
		margin: 0;
	}

	.mainChatBox {
		padding: 0;
	}

	.chatButton {
		display: block;
		margin-right: auto;
	}

	.textIcon p {
		display: none;
	}
}
