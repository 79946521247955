$font-family: Poppins;
$blue: #002a55;
$light-blue: #00c6ff;
$text-primary: #ffffff;
$secondary: #7f89be;
$dark-background: #0d0f22;
$text-purple: rgb(153, 84, 233);
$backgroud-color: #121528;
$text-dark: #4a517e;
$heading-color1: #ffffff;
$heading-color2: #d9def4;
$border-color1: #2e2e48;
$button-gradient1: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%);
$button-gradient2: linear-gradient(
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 0)
	),
	linear-gradient(to right, #fc466b 0%, #3f5efb 100%);
$button-gradient3: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%);
$text-gradient1: 42.12deg, #fc466b 30%, #3f5efb 100%;
$text-gradient2: left, #00c6ff, #0072ff;
$red: #e42a5b;

$font-family: "font-family";
$blue: "blue";
$light-blue: "light-blue";
$text-primary: "text-primary";
$secondary: "secondary";
$dark-background: "dark-background";
$text-purple: "text-purple";
$backgroud-color: "backgroud-color";
$text-dark: "text-dark";
$heading-color1: "heading-color1";
$heading-color2: "heading-color2";
$border-color1: "border-color1";
$button-gradient1: "button-gradient1";
$button-gradient2: "button-gradient2";
$button-gradient3: "button-gradient3";
$text-gradient1: "text-gradient1";
$text-gradient2: "text-gradient2";
$red: "red";

$themes: (
	default: (
		$font-family: Poppins,
		$blue: #002a55,
		$light-blue: #00c6ff,
		$text-primary: #000,
		$secondary: #4a517e,
		$dark-background: #d1e7fe,
		$text-purple: rgb(153, 84, 233),
		$backgroud-color: #fff,
		$text-dark: #d1e7fe,
		$heading-color1: #0d0f22,
		$heading-color2: #0d0f22,
		$border-color1: #2e2e48,
		$button-gradient1: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%),
		$button-gradient2: linear-gradient(to right, #fc466b 0%, #3f5efb 100%),
		$button-gradient3: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%),
		$text-gradient1: linear-gradient(42.12deg, #fc466b 30%, #3f5efb 100%),
		$text-gradient2: linear-gradient(left, #00c6ff, #0072ff),
		$red: #e42a5b,
	),
	dark: (
		$font-family: Poppins,
		$blue: #002a55,
		$light-blue: #00c6ff,
		$text-primary: #ffffff,
		$secondary: #7f89be,
		$dark-background: #0d0f22,
		$text-purple: rgb(153, 84, 233),
		$backgroud-color: #121528,
		$text-dark: #4a517e,
		$heading-color1: #ffffff,
		$heading-color2: #d9def4,
		$border-color1: #2e2e48,
		$button-gradient1: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%),
		$button-gradient2: linear-gradient(to right, #fc466b 0%, #3f5efb 100%),
		$button-gradient3: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%),
		$text-gradient1: linear-gradient(42.12deg, #fc466b 30%, #3f5efb 100%),
		$text-gradient2: linear-gradient(left, #00c6ff, #0072ff),
		$red: #e42a5b,
	),
);

@mixin themed() {
	@each $theme, $map in $themes {
		.theme--#{$theme} & {
			$theme-map: () !global;
			@each $key, $submap in $map {
				$value: map-get(map-get($themes, $theme), "#{$key}");
				$theme-map: map-merge(
					$theme-map,
					(
						$key: $value,
					)
				) !global;
			}
			@content;
			$theme-map: null !global;
		}
	}
}

@function t($key) {
	@return map-get($theme-map, $key);
}
