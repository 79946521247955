.rootDark {
	background: #121528 !important;
}

.rootLg {
	background: #fff !important;
}

.wrapper {
	display: flex;
	margin: 0;
	padding: 0;
	/* height: 100%; */
	box-sizing: border-box;
	font-family: "Poppins";
}

.wrapper::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.container {
	margin: auto;
	width: 100rem;
	display: flex;
	justify-content: center;
	height: 90.9vh;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	&::-webkit-scrollbar {
		display: none;
	}
}

.cont {
	margin: 2rem;
	/* padding: 2rem; */
	width: 45rem;
	height: fit-content;
	display: flex;
	flex-direction: column;
	border: 0px solid #ccc;
	border-radius: 1.2rem;
	/* box-shadow: 0 .315rem 1.05rem rgba(0, 0, 0, 0.068); */
	background: transparent !important;
}

.noNotifications {
	color: #888;
	align-self: center;
	width: 100%;
	text-align: center;
	margin-top: 20px;
}

.header {
	/* margin-bottom: 1rem; */
	display: flex;
	h2 {
		font-size: 1.3rem;
		font-weight: 700;
		cursor: pointer;
		color: #d9def4;
		position: relative;
		&:nth-child(1) {
			padding-right: 1rem;
			&::after {
				content: "";
				width: 2px;
				top: 10%;
				height: 70%;
				background-color: #c1c1c1;
				position: absolute;
				right: 0;
				transform: rotate(25deg);
			}
		}
		&:nth-child(2) {
			padding-left: 1rem;
		}
	}
	.activeTab {
		background-image: linear-gradient(90deg, #00c6ff, #0072ff);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.tab {
		color: #d9def4;
	}
}

.ads {
	margin: 2rem;
	width: 25rem;
	height: 20rem;
	border: 0px solid #ccc;
	border-radius: 1rem;
}

.friendRequestCard {
	width: 100%;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	padding: 1rem;
	border-left: 4px solid #ff5b5b;
	display: flex;
	align-items: center;

	.left {
		flex: 1;
		align-items: center;
		display: flex;
		.avatar {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;
		}
		.requestCont {
			flex: 1;
			font-size: 0.9rem;
		}
	}
	.actions {
		button {
			margin: 0 5px;
		}
	}
}

.thoughtsTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	color: #7f89be;
	margin: 20px;
	border-radius: 9px;
}

.activeTab {
	// background: #4a517e;
	background: linear-gradient(92.12deg, #00c6ff, #0072ff);
	color: #fff;
	border-radius: 9px;
	padding: 8px;
	width: 100%;
	text-align: center;
}

.tab {
	padding: 8px;
	width: 100%;
	text-align: center;
}

.notifNav {
	width: 100%;
	// background: #030413 !important;
	padding: 15px 20px;
	position: fixed;
	z-index: 1;
}

@media screen and (max-width: 768px) {
	.wrapper {
		height: 100%;
	}
	.container {
		display: flex;
		height: 100%;
		/* flex-direction: column; */
	}
	.cont {
		margin: 2rem auto 0;
		width: 90%;
	}

	.ads {
		display: none;
	}
	.header {
		margin-bottom: 1rem;
	}

	.friendRequestCard {
		flex-direction: column;

		.actions {
			margin-top: 0.6rem;
			display: flex;
			width: 100%;

			button {
				flex: 1;
				box-shadow: none;
			}
		}
	}
}
