@import "../auth.scss";
@import "../../../Themes/ThemeSelector.scss";

.logoM {
	margin: 0;
	width: 5rem;
	margin-bottom: 2rem;
}

.header {
	margin: 0 auto;
	margin-top: 10rem;
	width: 40rem;
	text-align: center !important;
	h1 {
		font-size: 4.5rem;
		@include themed() {
			color: t(heading-color1) !important;
		}
	}

	p {
		@include themed() {
			color: t(secondary) !important;
		}
	}
}

.auth-page-Container {
	.yellowDiv {
		background: linear-gradient(
			215.64deg,
			#fce9e9 -23.48%,
			#fbcece -0.64%,
			#fbdfdf 3.73%,
			#f89f9f 85.69%
		);
	}

	.pinkOval {
		background: linear-gradient(
			180.57deg,
			#ffd166 0.49%,
			rgba(255, 130, 102, 0) 147.16%
		);
	}
	.container {
		// border: 1px solid red;
		padding-top: 3rem;
		.register-form-container {
			.signup-form {
				// border: 1px solid red;
				display: flex;
				padding: 0 4rem;
				form {
					width: 100%;
					display: flex;
					flex-direction: column;
				}

				.password-field {
					padding-bottom: 0;
					min-width: 100%;
				}

				.login-btn {
					padding-top: 0.5rem;
					font-size: 0.9rem;
					button {
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.loader {
						height: 100%;
						margin-left: 10px;
					}
					.MuiCircularProgress-colorPrimary {
						color: white !important;
					}
				}

				.disclaimer {
					color: #8692a6;
					font-size: 0.8rem;
				}

				.no-account {
					padding: 1rem 0;
					p {
						text-align: center;
						font-weight: 400;
						font-size: 0.9rem;
						a {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

.password-msg {
	max-width: 350px;
}
@media screen and (max-width: 1200px) {
	.password-msg {
		max-width: 250px;
	}
}

@media screen and (max-width: 1000px) {
	.password-msg {
		max-width: 150px;
	}
}
@media screen and (max-width: 768px) {
	.password-msg {
		max-width: 100%;
	}
	.auth-page-Container {
		.container {
			padding: 0;
			.register-form-container {
				padding: 0;
				.signup-form,
				.header {
					padding: 0 0.6rem;
				}
			}
		}
	}
}

/* Styling For Mobile View */
