.mainContainer {
	padding: 2.2rem 0rem 2rem 1.5rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	z-index: 0;
}

.bredcrumb {
	display: flex;
	color: #7f89be;
	padding-left: 1.2%;
	cursor: pointer;
	font-family: Poppins;
}

.bredcrumb h3 {
	letter-spacing: 0.3px;
	font-size: 1.25rem;
	margin: 0 1rem 0 0;
}

.bredcrumb h3 img {
	margin-right: 0.5rem;
}

.statusDisplayDk,
.statusDisplayLg {
	display: flex;
	align-content: flex-start;
	flex-wrap: wrap;
	width: 100%;
	flex: 1;
	margin-top: 1.4rem;
	justify-content: flex-start;
	overflow-y: scroll;
}

.statusDisplayDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}

.statusDisplayDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}

.statusDisplayDk::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.statusDisplayDk::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}

@media only screen and (max-width: 600px) {
	.mainContainer {
		padding: 0;
		width: 100%;
	}

	.bredcrumb {
		padding: 0;
		padding-left: 5%;
	}
	.bredcrumb h3 {
		padding-left: 0.4rem;
		margin-top: 0.9rem;
	}
	.statusDisplay {
		flex-direction: column;
		align-items: center;
		/* justify-content: center;  */
		padding-left: 10%;
		margin-top: 3.5rem;
	}
}

.thoughtsTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #7f89be;
	margin: 20px;
	border-radius: 9px;
	width: 90%;
}

.activeTab {
	background: #4a517e;
	color: #fff;
	border-radius: 9px;
	padding: 8px;
	width: 100%;
	text-align: center;
}

.tab {
	padding: 8px;
	width: 100%;
	text-align: center;
}

.mobileContainer{
padding: 0 1rem;
}

.rootDark {
	background: #121528 !important;
}

.rootLg {
	background: #fff !important;
}



.mobNav {
	width: 100%;
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	background: #030413;
	/* padding: 7px; */
	padding: 0.7rem 1rem;
}

.headerText h2,
.headerText2 h2,
.headerText3 h2 {
	font-size: 17px;
	margin-left: 1rem;
}

.darkPText {
	color: #d9def4 !important;
}

.lgPText {
	color: #0d0f22 !important;
}