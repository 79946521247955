.recentSearch {
	margin: 10px 0;
	padding: 10px 0;
	font-size: 1rem;
	border-bottom: 1px solid #2e2e48;
	display: flex;
	align-items: center;
}

.resultBox {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.status {
	width: 100%;
	overflow: auto;
	scrollbar-width: none;
}

.cards {
	margin: 1rem 0;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width: none;
}

.msgResult {
	margin: 0 auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.msg {
	width: 100%;
}
