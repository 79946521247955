.homeContainer {
	display: flex;
}

.homeContainer:before {
	content: "\200B";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
.center {
	margin: auto;
	padding: 0;
	width: 100rem;
	height: 90.9vh;
	display: flex;
	overflow: hidden;
}

.imgCont {
	width: 80%;
	height: 100%;
	max-height: 850px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.img {
	height: 30rem;
	width: 30rem;
}

.thoughtsTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #7f89be;
	margin: 20px 10px;
	border-radius: 9px;
}

.activeTab {
	background: #4a517e;
	color: #fff;
	border-radius: 9px;
	padding: 8px;
	width: 100%;
	text-align: center;
}

.tab {
	padding: 8px;
	width: 100%;
	text-align: center;
}

.rootDark {
	background: #121528 !important;
}

.rootLg {
	background: #fff !important;
}

.searchContainer {
	margin: 0 20px;
	margin-bottom: 1rem;
	padding: 0.5rem;
	width: auto;
	display: flex;
	align-items: center;
	transition: 200ms cubic-bezier(0.56, 0.04, 0.33, 1.26);
	border: 1px solid #7f89be;
	box-sizing: border-box;
	border-radius: 8px;
}

.searchContainer .searchIcon {
	color: #7f89be;
}

.inputField {
	margin-left: 0.1em;
	border: 0;
	font-size: 15px;
	font-weight: 400;
	flex: 1;
	padding: 0.05rem 0.15rem;
	text-align: left;
	border: none;
	border-radius: 6px;
	background: transparent;
	color: #7f89be;
}

.inputField::placeholder {
	color: #7f89be;
	text-align: left;
}

@media only screen and (max-width: 768px) {
	.center {
		height: 100%;
		position: relative;
	}
	.homeContainer {
		display: flex;
		height: 100%;
	}

	.centerExpanded {
		flex: 1;
	}

	.imgCont {
		display: none;
	}
}
