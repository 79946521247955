.container {
	background-color: white;
	width: 96%;
	margin-left: 2%;
}
.callContainer {
	display: flex;
	flex-direction: row;
}
.moreIcon {
	margin: auto 0;
	margin-left: -2%;
}
.callOptionsContainer {
	// border: 1px solid red;
	padding: 0.8rem 0;
	// display: flex;
	justify-content: center;
	margin: 0 auto;
	width: fit-content;
}
.crossIcon {
	margin-top: 1px;
	margin-right: 19px;
}
.modalBody {
	padding: 0.5rem 2rem;
	margin: 0 auto;
}
.links {
	margin: 0 auto;
	padding: 0 auto;
	padding-bottom: 1rem;
	width: 100%;
	display: flex;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 1.15rem;
	line-height: 1.48rem;
	justify-content: center;
	border-bottom: 1px solid #ececec;
}

.link,
.selected {
	margin-right: 4.5rem;
	cursor: pointer;
	color: rgba(85, 85, 85, 1);
	/* text-decoration: underline; */
}

.link:hover,
.selected {
	color: #fd5d5f;
	padding-bottom: 0.3rem;
	border-bottom: 1px solid #fd5d5f;
}
.settings {
	margin: 2rem auto;
	padding: 1rem auto;
	max-width: 22rem;
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.dropdown {
	width: 100%;
	padding: 0.4rem 0.4rem;
	color: #434343;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 0.95rem;
	line-height: 16px;
	outline: none;
	border: 1px solid #e6e6e6;
	box-sizing: border-box;
	border-radius: 8px;
	background-color: white;
	margin: 1rem 1rem;
}
.dropHeading,
.dropHeading1 {
	margin-top: 1.5rem;
	text-align: center;
	margin-bottom: -0.3rem;
}
.dropHeading1 {
	margin-top: 1.5rem;
}
