.subCont {
	margin: 10px 0px;
	padding: 1rem;
	display: flex;
	align-items: center;
	font-family: "Poppins";
	font-weight: 400 !important;
	font-size: 1rem;
	color: #d9def4;
	background: #7f89be;
	overflow: hidden;
	border-radius: 1rem;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
}

.seen {
	background: #4a517e;
	border-left-color: transparent;
}

.hide {
	display: none;
}

.content {
	flex: 1;
	margin: 0 0.5rem;
	font-weight: 400;
}

.date {
	align-self: flex-end;
}

.date span {
	display: flex;
	align-items: center;
	font-size: 0.7rem;
}

.date span svg {
	font-size: 0.95rem;
	margin-right: 3px;
}

@media screen and (max-width: 768px) {
	.subCont {
		margin: 10px 0px;
		padding: 10px 0;
		display: flex;
		align-items: center;
		font-family: "Poppins";
		font-weight: 400 !important;
		font-size: 1rem;
		background: none;
		overflow: hidden;
		border-radius: 0;
		box-shadow: none;
		border-bottom: 1px solid #2e2e48;
	}

	.content {
		margin: 0 5px;
		color: #7f89be !important;
	}
	.seen {
		color: #7f89be !important;
		opacity: 0.8;
		background: none;
		border-left-color: transparent;
	}
}
