@import "../../../../Themes/ThemeSelector.scss";

.mainContainer {
	@include themed() {
		background: t(backgroud-color);
	}
}

.selectBread {
	@include themed() {
		color: t(heading-color2);
	}
}

.notSelected {
	@include themed() {
		color: t(secondary);
	}
}

*::-webkit-scrollbar {
	width: 1px !important;
}

*::-webkit-scrollbar-track {
	@include themed() {
		background: t(secondary);
	}
}

*::-webkit-scrollbar-thumb {
	@include themed() {
		background-color: t(heading-color1);
		border: 1px solid t(heading-color1);
	}
	border-radius: 20px;
}
