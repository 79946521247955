.popover {
	display: flex;
	flex-direction: column;
}

.memberCont {
	display: flex;
	align-items: center;
	padding: 0.5rem;
	cursor: pointer;
	transition: 400ms;
	border-radius: 5px;
	margin: 0 0.5rem;
}

.username {
	font-size: 0.9rem;
	flex: 1;
	font-weight: 400;
	color: #888;
	margin: 0 0.5rem 0 0.5rem;
}

.adminTag {
	padding: 0.1125rem 0.5rem;
	background: linear-gradient(
		112.23deg,
		rgba(196, 196, 196, 0.17) 0%,
		#00c6ff 0.01%,
		#0072ff 100%
	);
	color: #fff;
	font-size: 0.9rem;
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
}

.online {
	margin: 0 1rem;
	font-size: 0.7rem;
	color: #888;
}

.info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.info a {
	width: 100%;
}

.img {
	width: 5rem !important;
	height: 5rem !important;
	border-radius: 50% !important;
}

.btns {
	display: flex;
	justify-content: space-around;
}

.btn,
.pinkBtn {
	margin: 0.15rem 0;
	padding: 0.15rem 0.5rem;
	outline: none;
	border: none;
	border-radius: 4px;
	background: transparent;
}

.btn:hover {
	color: #ff5b5b;
}

.pinkBtn {
	background: transparent linear-gradient(71deg, #ff5b5b 0%, #dba3db 100%) 0%
		0% no-repeat padding-box;
	color: #fff;
	width: 100%;
}

.outlineBtn {
	margin: 0.5rem;
	padding: 0.15rem 0.5rem;
	border: 1px solid #ff5b5b;
	border-radius: 4px;
	font-size: 0.9rem;
	background: #fff;
	color: #ff5b5b;
	box-sizing: border-box;
}

.outlineBtn:hover {
	background: transparent linear-gradient(71deg, #ff5b5b 0%, #dba3db 100%) 0%
		0% no-repeat padding-box;
	color: #fff;
}
