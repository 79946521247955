@import "../../../../../Themes/ThemeSelector.scss";

.closeButton {
	position: absolute;
	right: 0;
}

.dialog {
	width: 330px;
	position: relative;
}

.friendList {
	display: flex;
	flex-direction: column;
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #ff5b5b;
	}
}

.friend {
	display: flex;
	padding: 10px 2rem;
	align-items: center;
	cursor: pointer;
	.avatar {
		margin-right: 0.8rem;
	}
	p {
		flex: 1;
		margin-bottom: 0;
	}
}

.loader {
	display: flex;
	justify-content: center;
}
