$popover-width: 270px;
.popover {
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
}

.dialog {
	width: $popover-width;
}

.container {
	width: $popover-width;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 2rem;
	min-width: 700px;
	// min-width: max-content;
}

.searchBox {
	padding: 0.35rem 0.75rem;
	width: 100%;
	align-items: center;
	display: flex;
	border: 1px solid var(--secondary);
	border-radius: 8px;
	margin: 0.5rem auto;
}

.searchField {
	padding: 0.2rem;
	width: 100%;
	font-size: 1rem;
	background: var(--backgroud-color);
	color: var(--secondary);
	border: none;
}

input::placeholder {
	color: var(--secondary);
}

.header {
	display: flex;
	justify-content: space-between;
	h3 {
		font-weight: 500;
		font-size: 1.4rem;
		color: var(--heading-color1);
		// text-align: center;
	}
	padding: 1rem 0;
}

.addFolderContainer {
	display: flex;
	justify-content: space-between;
	// justify-content: center;
	gap: 3px;
}

.folderContainerDk,
.folderContainerLg {
	margin: 1rem 10;
	padding: 0 8px 0 0;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	max-height: 250px;
	&::-webkit-scrollbar {
		width: 0;
	}
}
.folderContainerDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}
.folderContainerDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}
.folderContainerLg::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.folderContainerLg::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}

.folder {
	margin: 10px 5px;
	padding: 13px 20px;
	display: flex;
	// max-width: $popover-width;
	align-items: center;
	min-width: 0;
	cursor: pointer;
	transition: 200ms;
	// border: 1px solid #0072ff;
	border-radius: 16px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

	.folderName {
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 20px;
		color: var(--heading-color2);
		text-transform: capitalize;
	}
}
.selectedFolder {
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
	border-radius: 1px solid #0072ff;
}

.loaderContainer {
	display: flex;
	justify-content: center;
	padding: 1rem 0;
}

.btn {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.thoughtsCount {
	font-size: 12px;
	color: #4a517e;
	text-transform: lowercase;
}

@media only screen and (max-width: 600px) {
	.dialog {
		width: 100%;
	}
	.container {
		max-width: 100%;
		min-width: 0px !important;
	}

	.folderContainer {
		.folder {
			max-width: 100%;
		}
	}
}
