.emptyContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: transparent;

	.imgContainer {
		opacity: 0.8;
		margin-bottom: 1rem;
		width: 30vw;
		img {
			width: 100%;
		}
	}

	.typography {
		max-width: 70%;
		text-align: center;
		font-weight: 500;
		font-family: inter, sans-serif;
		.heading {
			font-size: 1.7rem;
		}
		.subHeading {
			font-weight: 400;
			font-size: 1rem;
			max-width: 100%;
		}
	}
}

.users {
	.imgContainer {
		width: 40%;
	}
}

.error404Page {
	justify-content: center;
}

@media only screen and (max-width: 768px) {
	.users {
		position: relative !important;
	}
	.emptyContainer {
		position: absolute;
		justify-content: center;
		// top: 0;
		z-index: 0;
		.imgContainer {
			width: 70vw;
			img {
				width: 100%;
			}
		}
		.typography {
			max-width: 90%;
			.heading {
				font-size: 1.5rem;
			}
			.subHeading {
				padding: 0 1rem;
				font-size: 0.8rem;
			}
		}
	}
}

.darkText {
	color: #d9def4;
}

.lgText {
	color: #0d0f22;
}
