.modalBody {
	padding: 30px;
	font-family: Poppins;
}

.heading {
	margin-top: 2rem;
	font-size: 1.25rem;
	word-spacing: 0.2rem;
	font-family: "Poppins";
}

.statusField {
	width: 100%;
	height: 170px;
	background: transparent;
	border: none;
	font-size: 1.125rem;
	border-radius: 7px;
	padding: 1rem 1rem 0 1rem;
	resize: none;
}

.mobstatusField {
	width: 100%;
	height: 300px;
	background: transparent;
	border: none;
	font-size: 1.125rem;
	border-radius: 7px;
	padding: 1rem 1rem 0 1rem;
	resize: none;
}

.statusField::placeholder {
	color: #7f89be;
}

.lgtext {
	color: #0d0f22;
}

.darktext {
	color: #d9def4;
}

.darkBg {
	padding: 1rem;
	background: #0d0f22;
	color: #d9def4;
}

.lgBg {
	padding: 1rem;
	background: white;
	color: #0d0f22;
}

.textdarkBg {
	background: #121528;
}

.textlgBg {
	background: #d1e7fe;
}

.thoughtInfo {
	padding: 0 1rem 1rem 0;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.remaining1 {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 8px;
}

.exceed {
	display: flex;
	justify-content: left;

	color: var(--red);
	margin-bottom: -0.99rem !important;
	font-size: 12px;
	/* font-family: Roboto; */
}

.imageIcon {
	/* margin-bottom: 1.5rem; */
	margin-left: 1rem;
}

.fileIcon {
	/* margin-bottom: 1.5rem; */
	margin-left: 1rem;
}

.imageSelected {
	margin-left: 1rem;
}

.inLimit {
	margin: 0;
	color: #4a517e;
	/* font-family: Roboto; */
}

.suggestion {
	display: flex;
	flex-wrap: wrap;
}

.tagContainer {
	margin: 5px 10px;
}

.tag {
	cursor: pointer;
}

.icons {
	display: flex;
	/* align-items: center; */
	align-items: baseline;
	justify-content: space-between;
}

.bottomActions {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.crossIcon {
	margin-top: 15px;
	margin-right: 15px;
}

.colour_container {
	display: flex;
	max-width: 12rem;
	margin: 0 0.5rem;
	margin-right: 1.5rem;
	margin-top: 1rem;
	justify-content: center;
	align-items: center;
}

.single_colour {
	width: 32px;
	height: 36px;
	cursor: pointer;
	margin: 0.2rem -0.1rem;
	outline: none;
}

@media screen and (max-width: 768px) {
	.colour_container {
		flex-wrap: wrap;
		margin: 0;
		margin-right: 0.3rem;
	}
	.modalBody {
		padding: 10px;
	}
}
