.breadcrumb {
	display: flex;
	padding: 0 1.2rem;
	margin: 2rem 0 1rem;
	justify-content: space-around;
	font-family: "Poppins";
	font-size: 1.3rem;
}

.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: transparent;
	z-index: 10;
}

.message {
	display: flex;
	align-items: center;
}

.searchContainer {
	margin: 1rem 0;
	padding: 0.5rem;
	width: 100%;
	display: flex;
	align-items: center;
	transition: 200ms cubic-bezier(0.56, 0.04, 0.33, 1.26);
	border: 1px solid #7f89be;
	box-sizing: border-box;
	border-radius: 8px;
}

.searchContainer .searchIcon {
	color: #7f89be;
}

.search {
	font-size: 0.75rem;
	border: 0;
	width: 90%;
	flex: 1;
	font-family: "Poppins";
	color: #7f89be;
	font-weight: 400;
	background-color: transparent;
}
.search:disabled {
	background-color: transparent;
}

.search::placeholder {
	color: #7f89be;
}

.block {
	width: 30%;
	cursor: pointer;
	color: #bfbfbf;
	display: flex;
	border-bottom: 2px solid #bfbfbf;
	justify-content: center;
}

.select {
	margin-left: 1rem;
	width: 100%;
	cursor: pointer;
	display: flex;
	color: #7f89be;
}

.status {
	width: 100%;
	overflow-y: auto;
}

.status::-webkit-scrollbar {
	display: none;
}

.user {
	width: 100%;
}

.convListLG,
.convListDk {
	padding: 0 10px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	flex: 1;
}

.resultDivDk,
.resultDivLg {
	/* height: 60vh; */
	height: calc(100vh - 100%);
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 1rem;
	overflow: auto;
}
.resultDivDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}
.resultDivDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}
.resultDivLg::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.resultDivLg::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}

.header {
	font-size: 1.2rem;
	font-weight: 500;
	color: #ff5b5b;
	display: flex;
	padding: 1rem 0.5rem 0 0.5rem;
}
.header button {
	margin-top: 5px;
}

.loading {
	display: flex;
	width: 100%;
	height: 90%;
}

@media only screen and (max-width: 768px) {
	.container {
		background-color: white;
		z-index: 10;
		width: 100%;
		position: absolute;
	}
	.status {
		height: 70vh;
	}
	.resultDivDk,
	.resultDivLg {
		height: 90%;
		overflow: auto;
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 0.7rem;
		overflow: auto;
	}
	.searchContainer {
		margin: 0;
		margin-bottom: 1rem;
	}
}
