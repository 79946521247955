.container,
.container1 {
	margin-top: 1rem;
	overflow-y: auto;
	width: 100%;
	max-height: 100%;
}

.container::-webkit-scrollbar,
.container1::-webkit-scrollbar {
	display: none;
}

.section {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.groupHeading {
	margin: 0.2rem 0.5rem;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.heading {
	font-size: 1.25rem;
}

.textdark {
	color: #d9def4;
}

.textlg {
	color: #0d0f22;
}

.groupBtn {
	font-size: 0.9rem;
	background: linear-gradient(90deg, #fc466b, #3f5efb);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.groupsCont {
	margin: 0.5rem auto;
	width: 90%;
}

.searchBox {
	margin: 0.5rem 0.5rem 1rem 0.5rem;
	padding: 0.5rem;
	width: 90%;
	display: flex;
	align-items: center;
	border: 1px solid #7f89be;
	border-radius: 0.5rem;
}

.searchField {
	padding: 0.2rem;
	width: 100%;
	font-size: 1rem;
	background: var(--backgroud-color);
	color: var(--secondary);
	border: none;
}

.createBtn {
	margin: 0 auto;
	margin-bottom: 3rem !important;
	background: linear-gradient(225deg, #fc466b 0%, #3f5efb 100%);
	border-radius: 2rem !important;
	font-size: 1rem !important;
	color: #ffffff !important;
	font-family: "Poppins";
	text-transform: none;
	height: 45px;
	outline: none !important;
	width: 92%;
	font-style: normal;
	line-height: 19.9px;
	font-weight: 400;
	text-transform: none !important;
}

.myGrp,
.item {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	padding: 0.4rem 0;
	cursor: pointer;
	border-radius: 50rem;
	margin-bottom: 10px;
	padding-right: 5px;
	margin-left: -0.5rem;
	padding: 0.4rem;
	justify-content: space-between;
}

.hide {
	display: none;
}

.moreIcon {
	/* margin-left: auto; */
	/* margin-right: 0.5rem; */
}

.addIcon {
	margin-left: auto;
	margin-right: 0.7rem;
}

.grpname {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: var(--heading-color2);
}

.explore {
	margin-top: 1rem;
}

.hoverBtn {
	visibility: hidden;
	font-size: 1.4rem !important;
}

.item:hover .hoverBtn {
	visibility: visible;
	float: right;
}

.deleteBtn {
	font-size: 0.9rem !important;
	text-transform: none;
}

.item:hover {
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
}

.item:hover .grpname {
	color: #00c6ff;
}

.editPhoto {
	margin-left: 0.4rem;
}

.editPhoto img {
	width: 1rem;
	height: 1rem;
}

.bulb {
	margin-left: auto;
}
