.heading {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	cursor: pointer;
}

.name {
	margin: 0;
	margin-left: 5px;
	color: #222;
	font-size: 12px;
	font-family: Metropolis-Medium;
}

.footerRight {
	display: flex;
	align-items: center;
	flex: 1;
	color: #979797;
	justify-content: flex-end;
}

.likes,
.comments {
	display: flex;
	align-items: center;
	height: 100%;
}
.comments {
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 10px;
}

.count {
	font-size: 0.7rem;
	line-height: 0.7rem;
	color: #979797;
	/* margin-right: 3px; */
}

.users {
	padding-left: 5px;
	display: flex;
	height: 100%;
	/* width: 100.7px; */
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: break-word;
	word-wrap: break-word;
}
.dateTime {
	font-size: 0.7rem;
	margin-right: 5px;
	line-height: 0.75rem;
	color: #979797;
}
.time {
	display: flex;
	font-size: 0.7rem;
	font-family: Metropolis-Medium;
	justify-content: flex-end;
	color: #979797;
}
.image {
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
	margin-right: 0;
	margin-bottom: 1.7%;
	margin-left: auto;
	box-sizing: border-box;
	border-radius: 6px;
}
.imgCont {
	position: relative;
	bottom: 0;
}

.content {
	display: flex;
	align-items: center;
	flex: 1;
	position: relative;
	padding-left: 5px;
	text-align: left;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	overflow-wrap: break-word;

	margin-right: 0em;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 0.99rem;
	line-height: 19px;

	color: #2f2f2f;
}

.content1 {
	display: flex;
	align-items: center;
	flex: 1;
	position: relative;
	/*  margin-top: auto;
  margin-bottom: auto; */
	word-break: break-word;
	cursor: pointer;
	padding-left: 5px;
	padding-right: 2px;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: break-word;
	word-wrap: break-word;
	font-family: Roboto !important;
	font-style: normal;
	font-weight: normal;
	font-size: 0.99rem;
	line-height: 19px;

	color: #2f2f2f;
	margin-bottom: 8px;
}

.content2 {
	flex: 1;
	display: flex;
	align-items: center;
	word-break: break-word;
	cursor: pointer;
	padding-left: 5px;
	padding-right: 2px;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: break-word;
	word-wrap: break-word;

	font-family: Roboto !important;
	font-style: normal;
	font-weight: normal;
	font-size: 0.99rem;
	line-height: 19px;

	color: #2f2f2f;
	margin-bottom: 2.5rem;
}
