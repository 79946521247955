.tagsContainer {
	/* width: 17%; */
	padding-left: 1%;
	display: flex;
	flex-direction: column;
	font-family: "Poppins";
	font-weight: 400;
	position: fixed;
	/* right: 0; */
}

.backgroundText {
	/* position: fixed; */
	font-size: 1.6rem;
	padding-top: 3rem;
	font-family: "Poppins";
	font-weight: 700;
	/* height: 10rem; */
}

.header {
	display: flex;
	font-size: 1.25rem;
	color: var(--text-primary);
	font-weight: 400;
	padding: 0 1rem;
	margin-bottom: 5%;
}

.darktext {
	color: #d9def4;
}

.lgtext {
	color: #0d0f22;
}

.trendingIcon {
	margin-right: 5%;
}

.mainContent {
	/* margin: 7rem auto; */
	position: relative;
	/* text-align: center; */
	/* height: 91%; */
	scrollbar-width: none;
}
.mainContent::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.tag {
	color: var(--text-primary);
	font-size: 1rem;
	padding: 0.6rem 1rem;
	font-weight: 400;
	margin-bottom: 0;
}

.tagCount {
	font-size: 0.875rem;
	color: var(--secondary) !important;
}

.tag span {
	color: #878787;
}

.activeTag span {
	color: #ff5b5b;
}

.activeTag {
	font-weight: 500;
	color: #ff5b5b;
}

.loader {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 2rem 0;
}

.loader .loaderSpinner {
	font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
	.tagsContainer {
		overflow-y: auto;

		width: 100%;
		position: relative;
	}

	.backgroundText {
		/* position: fixed; */
		display: none;
		/* height: 10rem; */
	}

	.mainContent {
		margin: 0;
		padding: 0 2rem;
		text-align: left;
		position: initial;
	}

	.tag {
		color: #555;
		font-size: 1.1rem;
		padding: 0.8rem 0.8rem;
		font-weight: 450;
		margin-bottom: 0;
	}
}
