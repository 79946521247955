.notificationContainer {
	display: flex;
	flex-direction: column;
}
.videoContainer {
	background-color: #434343;
	display: flex;
	flex-direction: column;
	margin: 0 0;
	flex: 1;
	position: relative;
	color: white;

	&__header {
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 10;
		.back {
			color: white !important;
			text-transform: none !important;
			display: flex;
			align-items: center;
			svg {
				font-size: 2rem;
			}
			span {
				flex: 1;
				font-weight: 300;
				font-size: 1rem;
			}
		}
		.expandButton {
			color: white;
		}
	}
	&__footer {
		display: flex;
		justify-content: space-between;
		.info {
			.username {
				margin: 0 1rem;
			}
			display: flex;
			align-items: center;
			margin-left: 1.5rem;
		}
		padding: 0 1rem 1rem 0;

		position: relative;
		z-index: 10;
		&__own {
			justify-content: flex-end;
			margin-right: 1rem;
		}
	}

	.videoOff {
		z-index: -1;
	}

	video {
		position: absolute;
		// border: 1px solid red;
		width: 100%;
		height: 100%;
	}
	.info {
		.username {
			margin: 0.2rem auto;
			padding-left: -0.2rem;
		}
		display: flex;
		align-items: center;
		margin: 0.4rem auto;
		z-index: 10;
		padding-bottom: 0.4rem;
		width: 100%;
	}
	.avatarContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
	}
}
.bottomcontainer {
	background-color: white;
	padding: 0 5vw;
}

.callOptionsContainer {
	// border: 1px solid red;
	padding: 0.8rem 0;
	// display: flex;
	justify-content: center;
	margin: 0 auto;
	width: fit-content;
}
