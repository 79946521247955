.dialog {
	width: 330px;
	position: relative;
}

.closeButton {
	position: absolute;
	right: 0;
}

.title {
	padding: 3rem 0 2rem 2.5rem;
	h2 {
		font-weight: 700 !important;
		font-size: 1.3rem !important;
	}
}

.content {
	width: 100%;
	display: flex;
	align-items: center;
}

.recorder {
	display: flex;
	justify-content: center;
	align-items: center;

	.recorderBtn {
		position: relative;
	}
	.actions {
		flex: 1;
	}
}

.player {
	display: flex;
	justify-content: center;
	// min-height: 3rem;
	align-items: center;
}

.timer {
	display: flex;
	justify-content: center;
	align-items: center;
	// min-height: 3rem;
	font-size: 1.5rem;
	flex: 1;
}

.buttonContainer {
	margin-left: auto;
}
