@import "../../../../Themes/ThemeSelector.scss";

.container {
	padding: 0 1rem 0 1.5rem;
	display: flex;
	flex-direction: column;
	font-family: inherit;
	font-weight: 400;
	background-color: var(--background);
}

.backgroundText {
	margin: 1rem 0;
	display: flex;
	font-size: 1.25rem;
	font-weight: 400;
	color: var(--text-primary);
	.header {
		margin-left: 0.5rem;
	}
}

.searchContainer {
	border: 1px solid #7f89be;
	display: flex;
	align-items: center;
	position: relative;
	padding: 0.5rem;
	border-radius: 0.5rem;
	margin: 1rem 0;
	height: 48px;
	input {
		width: 100%;
		font-weight: 400;
		border: none;
		color: #b2b2b2;
		font-size: 0.8rem;
		position: relative;
		background: transparent;
	}

	&:focus-within {
		border-color: var(--text-primary);
	}
}

.searchIcon {
	margin-top: 4px;
}

.content {
	margin: 1rem 0;
	display: flex;
	flex: 1;
	flex-direction: column;
	position: relative;
	overflow-y: auto;
	border-right: 1px solid #7f89be;
	padding: 0 10px 0 0;
	&::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
}

.card {
	padding: 0.5rem 0.5rem;
	&:hover {
		// color: #979797;
		color: var(--text-primary);
	}
	display: flex;
	align-items: center;
	color: var(--heading-color2);
	flex: 0;
	font-weight: 400;
	font-size: 0.8rem;
	border-radius: 100rem;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	margin-bottom: 5%;

	&__active {
		border: 1px solid #00c6ff;
		color: var(--text-primary);
		box-shadow: 0px 4px 10px 0px #00c6ff0d;
	}
	.cardHeader {
		display: flex;
	}

	.username {
		display: flex;
		align-items: center;
		margin: 0.25rem 0.85rem;
		word-break: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		overflow-wrap: break-word;
		word-wrap: break-word;
	}
}

.count {
	margin: 0.5rem;
	margin-left: auto;
	padding: 0.1rem 0.5rem;
	background: linear-gradient(92.12deg, #fc466b 0%, #3f5efb 100%);
	border-radius: 50%;
	color: #fff;
}

.loader {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 2rem 0;
}

.loader .loaderSpinner {
	font-size: 1.2rem;
}

.darkrow {
	color: #d9def4 !important;
}

.lgrow {
	color: #0d0f22;
}

.grpContainer {
	margin: 1rem 0 0.5rem 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.groupName {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.groupName p {
	margin: 0;
}

.grpname {
	margin-left: 0.75rem;
	font-size: 1rem;
}

.icon {
	margin-right: 0.5rem;
}
.para {
	height: 10vh;
	margin: 0.8rem 0 1.5rem;
	color: #d9def4;
	h5{
		color: #D9DEF4;
	}
	p{
		margin-top: 0.8rem;
		font-size: 13px;
		color: white;
		opacity: 0.8;
	}
}
