.rootDark {
	background: #121528 !important;
}

.rootLg {
	background: #fff !important;
}

.textLightTheme {
	/*
		color: #0D0F22 !important;
	*/
	color: #0d0f22;
}

.textDarkTheme {
	color: #d9def4 !important;
}

.textLightP {
	color: #4a517e !important;
}

.textDarkP {
	color: #7f89be !important;
}

.wrapper {
	max-width: 100%;
	display: flex;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins";
}

.wrapper::before {
	content: "";
	display: inline-block;
	vertical-align: middle;
}
.rightWrapper {
	width: 80%;
	margin: 1.5em auto;
	padding-left: 0.5%;
	margin-left: 2.5rem;
}

@media (max-width: 768px) {
	.rightWrapper {
		width: 100%;
		margin: 1.5em auto;
		padding-left: 0%;
	}
}
.rightFaqWrapper {
	width: 80%;
	margin: 0.8em auto;
}

.tutorialContent {
	color: var(--heading-color2);
	text-align: center;
}

.contentTutorial {
	margin-left: 0% !important;
}

.contentTutorial a {
	margin: 0 auto;
}

.btn2 {
	background: transparent;
	border: 0;
}

.aboutSubHeading {
	color: var(--heading-color2);
	font-size: 1rem;
	margin-bottom: 15px;
}

.aboutUsImage {
	margin: 2% 0;
}

.input {
	margin-left: 0.6em;
	border: 0;
	font-size: 1rem;
	font-weight: 400;
	flex: 1;
	padding: 0.05rem 0.15rem;
	text-align: left;
	border: none;
	border-radius: 6px;
	background: transparent;
	color: #7f89be !important;
}

.searchContainer {
	margin: 0.5rem 1rem 0.5rem 1rem;
	border: 1px solid #7f89be;
	max-width: 90%;
	padding: 10px;
	border-radius: 6px;
	display: flex;
	margin-left: 3rem;
}

@media (max-width: 768px) {
	.searchContainer {
		margin: 0.5rem 1rem;
		border: 1px solid #7f89be;
		max-width: 90%;
		padding: 10px;
		border-radius: 6px;
		display: flex;
	}
}

.boxes {
	background-color: var(--background-color) !important;
}

.boxHead {
	background-color: var(--background-color) !important;
}

@media screen and (min-width: 768px) {
	.rightFaqWrapper {
		width: 90%;
		margin: 0.8em 3rem;
	}
}

.iconImg {
	width: 1rem;
	height: 1rem;
	margin-right: 0.5rem;
}

.policyHeader {
	margin: 0 4rem;
	padding: 4rem 0.7rem 2rem 0.7rem;
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* color: var(--heading-color2); */
	color: #d9def4;
}

.policyBody {
	display: flex;
	overflow-y: hidden;
	margin: 0 4rem;
}

.container {
	margin: auto;
	width: 100rem;
	overflow: hidden;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	height: 96vh;
}

.MuiAccordion-root:before {
	top: -1px;
	left: 0;
	right: 0;
	height: 0px !important;
	content: "";
	opacity: 0 !important;
	position: absolute;
	transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	background-color: rgba(255, 255, 255, 0.12);
}
.left {
	margin: 0;
	display: flex;
	width: 25%;
	border-right: 1px solid #4a517e;
}

.sidebar {
	height: 100vh;
	width: 100%;
	padding: 0;
	padding-left: 3%;
	list-style: none;
	overflow: hidden;
	padding-right: 2rem;
}

.sidebar::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.link {
	padding: 1rem;
	width: 100%;
	opacity: 0.99;
	font-size: 14px;
	margin-right: 36px;
	box-sizing: border-box;
	/* border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent; */
}

.selected {
	padding: 1rem;
	width: 100%;
	font-size: 14px;
	color: var(--heading-color2);
	opacity: 1;
	/* border-left: 0.3rem solid #fd5d5f; */
	background: var(--button-gradient3);
	border-radius: 8px;
	margin-right: 36px;
}

.right {
	margin: 0 auto;
	width: 100%;
	height: 100%;
	overflow: auto;

	scrollbar-width: none;
}

.right::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.right {
	margin-bottom: 3%;
	padding-right: 4%;
	/* color: rgba(0, 0, 0, 0.5); */
}

.heading {
	margin-top: 1rem;
	margin-left: 1rem;
}
.head {
	margin-left: 3.8rem;
}
.content {
	font-size: 1rem;
	color: #7f89be;
	line-height: 1.75rem;
}

.content a {
	font-size: 1rem;
	color: #7f89be;
	text-decoration: underline;
}

.content1 {
	font-size: 1rem;
	color: #434343;
	width: 100%;
}
.btn {
	padding: 1.5% 8%;
	align-content: center;
	/* font-family: Metropolis-Medium; */
	color: #ffffff;
	background: #fd5d5f;
	border-radius: 6px;
	opacity: 1;
	margin-right: 43%;
	border: none;
}
.btn1 {
	padding: 1.5% 8%;
	align-content: center;
	/* font-family: Metropolis-Medium; */
	color: #ffffff;
	background: #fd5d5f;
	border-radius: 6px;
	opacity: 1;
	margin-left: 38%;
	margin-top: 5%;
	border: none;
}

.red {
	border: 0.5px solid #7f89be;
	background: var(--background-color);
	color: #7f89be;
}

.red:focus {
	background: var(--background-color);
	color: #7f89be;
}

.red option {
	background: var(--background-color);
}

.divStyle {
	display: flex;
	justify-content: space-between;
}

.ShareSocial {
	width: 100%;
}
.shareContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.shareIcon {
	margin-left: 0.5rem;
	margin-right: 1.5rem;
}
.shareText {
	font-style: normal;
	font-weight: 400;
	font-size: 1.05rem;
	line-height: 1.39rem;
	color: #312e2e;
}

.shareClose {
	position: absolute !important;
	top: 1.85rem;
	right: 1.3rem;
}
.shareSocialIcons {
	margin-top: 0.95rem;
	margin-bottom: 1.1rem;
}
.shareSocialCopy {
	margin-bottom: 0.6rem;
}
.pop {
	border: 0 !important;
	box-shadow: 0 0 10px 0 #bfbfbf;
}

.cont {
	width: 9rem;
	height: 6rem;
	padding: 0.6rem;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}
.MuiAccordion-root {
	border: none !important;
	box-shadow: none !important;
}
.boxes {
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 8px !important;
	border: none !important;
	border-top: 0px solid white;
	margin: 1.2em 0;
	background: var(--background-color);
}
.boxHead {
	padding-top: 0.4em;
	padding-bottom: 0.4em;
	background-color: var(--background-color) !important;
}
.boxbody {
	margin-top: -0.5em;
	color: var(--heading-color2);
	font-size: 1rem;
}
.popoverHead {
	margin: 0;
	width: 100%;
	font-size: 16px;
	display: flex;
	justify-content: space-between;
	background-color: var(--background-color) !important;
	color: #7f89be;
}
.expansion-panel {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none !important;
}
.popoverBtn {
	outline: none;
	border: none;
	background: var(--background-color);
}

.valueCont {
	margin: 7rem 0;
	display: flex;
	justify-content: space-between;
}

.valueHead {
	width: 40%;
}
.values {
	width: 40%;
	padding-right: 1.5rem;
}

.value {
	font-size: 1.5rem;
}

.team {
	position: relative;
}

.teamCont {
	display: flex;
	justify-content: space-around;
}

.imgCont {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.img {
	margin: 1rem;
	width: 15rem;
	height: 15rem;
	background: #f7f8fa;
}

.name {
	font-size: 1.2rem;
	margin-bottom: 0;
}

.role {
	font-size: 0.8rem;
	color: #999;
}

.contact {
	margin-top: 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contactBtn {
	padding: 0.5rem 2rem;
	outline: none;
	border: none;
	color: #fff;
	background: linear-gradient(45deg, #ff5b5b 0%, #dba3db 100%);
	border-radius: 10px;
}
.subHeading {
	margin: 2rem 0;
	/* padding: 0 2rem; */
	font-size: 1.3rem;
}
.shareDialog {
	width: 20rem;
	margin-right: 0 !important;
	margin-left: 3rem;
}

.tutorialButtonContainer {
	display: inline-flex;
	justify-content: center;
	align-self: center;
	padding: 0;
	margin: 0;
}
.contentTutorial {
	padding-top: 2em;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-left: 10%;
}
.tutorialButton {
	width: fit-content;
	padding: 1rem 2rem;
	font-size: 1.1rem;
	background: #ff5b5b;
	border: 1px solid #ff5b5b;
	border-radius: 6px;
	/* justify-content: center;
align-content: center; */
	margin: 0 12%;

	/* margin-right: 40%;
margin-top: 20%; */
}
.arrowBtn {
	margin-left: 2.6em;
	color: black;
	/* font-size: 1rem !important; */
	margin-right: 1em;
}
.header {
	color: var(--heading-color2);
	padding: 0rem 0.5rem 0.5rem 2rem;
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	font-size: 1.25rem;
}

.header h2 {
	margin: 2px 0 0 1.2rem;
	flex: 1;
	font-size: 1.5rem;
	font-weight: 500;
}

@media screen and (max-width: 768px) {
	ol {
		padding-inline-start: 15px;
	}
	ul {
		padding-inline-start: 25px;
	}
	.rightFaqWrapper {
		width: 90%;
		margin: 1rem auto;
	}
	.header {
		color: #fd5d5f;
		margin: 1.2rem 1.2rem 0rem -1rem;
		display: flex;
		/* justify-content: space-between; */
		/* align-items: center; */
	}

	.header h2 {
		margin: 0;
		flex: 1;
		font-size: 1.5rem;
		font-weight: 500;
	}
	.wrapper {
		height: 100%;
	}
	.ShareSocial {
		width: 100%;
	}
	.container {
		height: 100%;
		overflow: auto;
		border: none;
	}
	.blank {
		display: none;
	}

	.arrowBtn {
		margin: 1rem;
		color: #ff6c5c !important;
	}

	.sidebar::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}

	.link {
		margin: 1rem 0;
		padding-bottom: 1rem;
		padding-top: 0.5rem;
		width: 100%;
		font-size: 1.3rem;
	}
	.selected {
		margin: 1rem 0;
		padding-bottom: 1rem;
		padding-top: 0.5rem;
		width: 100%;
		font-size: 1.3rem;
		background: var(--button-gradient3);
		border-right: none;
	}
	.iconright {
		margin-left: auto !important;
	}

	.right {
		margin: 0;
		width: 100%;
		padding: 0px;
	}

	.sidebar {
		margin-top: 0;
		padding: 0;
		width: 100%;
		height: auto;
	}

	.link {
		margin: 1rem 0;
		padding: 1rem 1rem;
		width: 100%;
		font-size: 1.3rem;
		border-bottom: 1px solid #f2f2f2;
	}

	.heading {
		margin-top: 1.5rem;
		margin-left: -0.5rem;
	}
	.head {
		margin-left: 0.4rem;
	}
	.content1 {
		margin-top: -1rem;
	}
	.content {
		margin: 1rem 0rem;
		color: rgb(0, 0, 0);
		text-align: justify;
		/* opacity: 0.99; */
		/* font-size: 1.2rem; */
	}

	.btn {
		/* flex: 1; */
		width: 90%;
		margin-left: 5%;
		align-self: center;
	}

	.form {
		margin: 0 1rem 0 2rem;
		padding-right: 0;
		background: var(--dark-background) !important;
	}

	.divStyle {
		display: flex;
		flex-direction: column;
	}

	.divStyle > * {
		margin: 1rem 0;
	}

	.valueCont {
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.valueHead {
		width: 100%;
	}

	.values {
		width: 100%;
	}

	.value {
		margin: 0 2rem;
	}

	.imgCont {
		margin: 1rem;
		width: 50%;
	}

	.img {
		width: 100%;
		height: 10rem;
	}

	.contact {
		margin: 1rem 2rem;
		display: flex;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 4rem;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}

	.tutorialButtonContainer {
		height: 100%;
		display: flex;
	}
	.tutorialLink {
		display: flex;
		align-items: center;
	}
}

.dropdownNone {
	display: none;
}

.dropdownActive {
	padding: 0 1rem;
}

/* .lgText {
	color: #0d0f22;
}

.darkText {
	color: #d9def4;
} */

.link,
.selected,
.linkSub,
.selectedSub {
	list-style: none;
}

.selectedSub {
	margin: 8px 0 0 0;
}

.selectedSub svg,
.linkSub svg {
	margin-right: 5px;
}

.link,
.selected {
	padding: 1rem 1rem;
	width: 100%;
	opacity: 0.99;
	font-size: 1rem;
	box-sizing: border-box;
	display: flex;
	cursor: pointer;
	align-items: center;
}

.selectedSub,
.linkSub {
	margin: 0.5rem;
	padding: 0.25rem 0.5rem;
	width: 100%;
	opacity: 0.99;
	font-size: 1rem;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	cursor: pointer;
}

/* .link {
	color: #7f89be;
} */

/* .linkSub {
	color: #7f89be;
} */

.selected {
	color: #fff;
	opacity: 1;
	border-radius: 8px;
	background: linear-gradient(
		112.23deg,
		rgba(196, 196, 196, 0.17) 0%,
		#00c6ff 0.01%,
		#0072ff 100%
	);
	box-shadow: 0px 0px 10px #121528;
	z-index: 10;
}

.icon {
	margin-left: 0.5rem;
	font-size: 13px;
	transform: rotate(0deg);
	transition: all 0.2s ease-in-out;
}

.icon.open {
	transform: rotate(90deg);
}

.ploiciesTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	color: #7f89be;
	margin: 20px;
	border-radius: 9px;
	width: 90%;
	overflow-x: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
}

.activeTab {
	background: #4a517e;

	color: #fff;
	border-radius: 9px;
	padding: 10px 30px;
	white-space: nowrap;
}

.tab {
	padding: 10px 30px;
	white-space: nowrap;
}

.ploiciesTab::-webkit-scrollbar {
	display: none;
}

.contentMob {
	padding: 0px 10px;
}
