.wrapper {
	display: flex;
	margin: 0;
	padding-left: 1%;
	box-sizing: border-box;
	font-family: Metropolis-Medium;
}

.wrapper::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.container {
	margin: auto;
	width: 100rem;
	display: flex;
	height: 90vh;
}

.left {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-y: auto;
	width: 85%;
	scrollbar-width: none;
}

.left::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.heading {
	margin: 3.5rem 1.5rem 1.5rem 1.5rem;
	display: flex;
	align-items: center;
}

.headItem {
	display: flex;
	cursor: pointer;
	flex-direction: row;
}

.headItem h1 {
	font-family: "Poppins";
	font-size: 1.75rem;
	font-weight: 300;
}

.darkl {
	color: #d9def4 !important;
}

.lgl {
	color: #0d0f22 !important;
}

.cardsDk,
.cardsLg {
	margin: 0 1rem;
	display: flex;
	flex-wrap: wrap;
	/* width: 100%; */
	justify-content: flex-start;
	overflow: hidden;
	overflow-y: auto;

	scrollbar-width: none;
}

.cardsLg::-webkit-scrollbar {
	width: 1px solid !important;
	/* background: transparent; */
}

.cardsDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}
.cardsDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}

.cardsLg::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.cardsLg::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}

@media screen and (max-width: 768px) {
	.left {
		width: 100%;
	}
	.wrapper {
		padding-left: 0;
		height: 100%;
	}

	.heading {
		margin: 0.5rem 0 1rem;

		width: 100%;
		display: flex;
		justify-content: sleft;
		border-bottom: none;
	}

	.headItem {
		padding: 0 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-right: none;
	}
	.headItem h1 {
		font-size: 1.1rem;
	}

	#lasth1 {
		border-right: none;
	}

	.cards {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.card {
		width: 40%;
	}
	.container {
		height: 100%;
		width: 100vw;
	}
}
