.tagsContainer {
	margin-top: 4em;
	background-color: var(--backgroud-color);
}

.noIntrests {
	margin-top: 4em;
	display: flex;
	color: #d7d7d7;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.intrests {
	margin-top: 1rem;
}

.mobInterests {
	margin: 1rem 0;
	height: 50vh;
	overflow: auto;
}

.backgroundText {
	font-size: 2rem;
	font-family: Roboto;
	font-weight: 900;
	color: #ff5b5b;
}

.count {
	color: #d7d7d7;
}

.mainContent {
	position: relative;
	overflow: auto;
	overflow-y: auto;
	height: 68vh;
	scrollbar-width: none;
}

.mainContent::-webkit-scrollbar {
	width: 0;
	background: transparent;
}
.tag {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding-left: 0.5em;
	margin-bottom: 0;
	width: 100%;
	word-break: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: break-word;
	word-wrap: break-word;
}
.activetag {
	color: #ff5b5b;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding-left: 0.5em;
	margin-bottom: 0;
	width: 100%;
	word-break: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: break-word;
	word-wrap: break-word;
}
.tagContainer {
	width: 100%;
	display: flex;
	align-items: center;
	/* margin-left: -0.9em; */
}
.activetagContainer {
	width: 100%;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 2px;
	display: flex;
}
.chipContainer {
	width: 100%;
	/* margin-left: -0.9em; */
}
.chipContainer:hover {
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 2px;
}
.addBtn {
	cursor: pointer;
	padding: 5px;
}
.deleteBtn {
	margin: -10px -10px !important;
	text-transform: none;
}
.overlay {
	display: none !important;
	visibility: hidden !important;
}

.tagContainer:hover {
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 2px;
}
.activetagContainer:hover {
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 2px;
	width: 90%;
}
.activetagContainer:hover .hoverBtn {
	visibility: visible;
	float: right;
}
.activeTag {
	color: #ff5b5b;
}

.removeTag {
	padding: 5px;
	cursor: pointer;
}

.hoverBtn {
	visibility: hidden;
	/* float: right; */
	margin-bottom: 0.15em;
}
.tagContainer:hover .hoverBtn {
	visibility: visible;
	float: right;
	margin-top: -0.35em;
}
.loader {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 2rem 0;
}

.popover {
	display: flex;
	flex-direction: column;
}

.header {
	color: var(--text-primary);
	font-weight: normal;
	font-size: 1.25rem;
	padding: 1rem;
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
}

.header h2 {
	margin: 2px 0 0 1rem;
	flex: 1;
	font-size: 1.5rem;
	font-weight: 400;
}
.popoverBtn {
	margin: 0.5rem 0;
	padding: 0.25rem;
	outline: none;
	border: none;
	background: transparent;
}

.popoverBtn:hover {
	color: #ff5b5b;
}

.loader .loaderSpinner {
	font-size: 1.2rem;
}

.darkl {
	color: #d9def4 !important;
}

.lgl {
	color: #0d0f22 !important;
}

.chips {
	border-radius: 6px !important;
	font-size: 1em !important;
	font-family: Roboto !important;
	font-weight: 500;
	padding: 0.2rem 0.01rem !important;
	margin: 0.39em 0.3em;
	width: 90%;
	color: rgb(107, 107, 107);
}
.chips1 {
	border-radius: 6px !important;
	font-size: 1em !important;
	font-family: Roboto !important;
	font-weight: 500;
	padding: 0.2rem 0.01rem !important;
	margin: 0.39em 0.3em;
	color: rgb(97, 97, 97);
}
.closeIcon {
	color: white !important;
}

.hide {
	display: none;
	margin-top: 1em;
}

.searchBox {
	margin: 0.5rem auto;
	padding: 0.5rem;
	width: 82%;
	display: flex;
	align-items: center;
	border: 1px solid #7f89be;
	border-radius: 0.5rem;
}

.searchField {
	padding: 0.2rem;
	width: 100%;
	font-size: 1rem;
	background: var(--backgroud-color);
	color: var(--secondary);
	border: none;
}

.searchBtn {
	color: #fff5ff;
}

.SearchIcon {
	height: fit-content;
	margin-top: 12px;
	margin-left: 10px;
}

.link {
	display: inline-block;
	cursor: pointer;
	color: inherit;
	padding: 0.6rem 1rem;
	width: 80%;
}
.link:hover {
	color: inherit;
}
.post {
	/* float: right ; */
	/* margin-left: auto ; */

	margin: 1.1em 0 !important;
	color: #ffffff !important;
	width: 70%;
	font-family: roboto;
	background: #ff5b5b !important;
}

.postContainer {
	/* display: block;
      justify-content: center;
      align-self: center; */
	margin-left: 10% !important;
	/* margin-left: auto ; */
}
@media screen and (max-width: 768px) {
	.tagsContainer {
		border-left: none;
		padding: 0;
		margin-top: -1rem;
		/* margin-left: 1rem; */
		height: 83vh;
		width: 100%;
		/* position: relative; */
	}
	.header {
		color: #fd5d5f;
		/* padding: 0rem 0 0.5rem -1.6rem; */
		margin: 0 15px;
		margin-bottom: 0.5rem;
		margin-top: -1rem;
		display: flex;
		/* justify-content: space-between; */
		align-items: center;
	}

	.header h2 {
		margin: 2px 0 0 0.9rem;
		flex: 1;
		font-size: 1.5rem;
		font-weight: 500;
	}
	.searchBox {
		margin: 0 20px;
		padding: 0.5rem;
		width: 90%;
	}
	.tagContainer {
		width: 100%;
	}
	.tag {
		margin-top: 0.5rem;
		padding: 0;
	}
	.hoverBtn {
		visibility: visible;
		margin-bottom: 0.5rem;
	}

	.tagContainer:hover {
		box-shadow: none;
		border-radius: 1px;
		width: 100%;
	}

	.activetagContainer:hover {
		box-shadow: none;
		border-radius: 1px;
		width: 100%;
	}
	.chipContainer:hover {
		box-shadow: none;
		border-radius: 1px;
		width: 100%;
	}
	.link {
		width: 90%;
		display: inline-block;
		cursor: pointer;
		color: inherit;
		padding: 0rem 1rem;
	}
	.chipContainer {
		width: 100%;
	}
	.backgroundText {
		/* position: fixed; */
		font-size: 2rem;
		padding-top: 2rem;
		font-family: Roboto;
		font-weight: 900;
		color: #ff5b5b;
		/* height: 10rem; */
	}

	.mainContent {
		margin: 0;
		padding: 0 1rem;
		text-align: left;
		position: initial;
	}

	.tag {
		padding: 1rem 0.5rem;
		font-weight: 400;
		margin-bottom: 0;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.2rem;
		color: #434343;
	}
	.activetag {
		padding: 1rem 0.5rem;
		font-weight: 400;
		margin-bottom: 0;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
	}
	.popoverBtn {
		margin: 0rem 0;
		padding: 0.1rem;
		outline: none;
		border: none;
		background: transparent;
	}
}
