.container {
	font-family: Roboto;
	display: flex;
	padding: 1rem 0.3rem 1rem 1rem;
	font-size: 1.1rem;
	cursor: pointer;
	border-bottom: 1px solid #f2f2f2;
	.foldername {
		flex: 1;
		color: #878787;
	}
	&:hover {
		box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
	}
}

.selected {
	border-right: 1px solid #ff5d5d;
}

@media screen and (max-width: 768px) {
	.container {
	}
}
