.contHide {
	transform: translateX(100%);
	display: none;
}

.dataCont {
	flex: 1;
	overflow: auto;
}

.dataCont::-webkit-scrollbar {
	display: none;
}

.topContainer {
	margin: 2rem 0;
	font-size: 20px;
	display: flex;
	justify-content: space-between;
}

.contShow {
	padding: 1rem 0.5rem;
	width: 25%;
	display: flex;
	position: absolute;
	flex-direction: column;
	height: 100%;
	right: 0;
	top: 0;
	z-index: 200;
	background-color: transparent;
	box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.02);
	transform: translateX(0);
	transition: transform 0.3s ease-out;
	overflow-y: auto;
	scrollbar-width: none;
	font-family: "Poppins" !important;
}

.belowName {
	display: flex;
	justify-content: space-between;
}

.rating {
	cursor: default;
	display: flex;
	align-items: center;
	color: #888;
}

.rating span {
	margin-left: 5px;
	line-height: 0.9rem;
	font-size: 0.9rem;
}

.inactive {
	color: #666666;
	opacity: 0.8;
}

.contShow::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.imageCont {
	margin-top: 10px;
	display: flex;
	align-items: center;
	padding: 1rem 0;
}

.imageCont .image {
	width: 100%;
	overflow: hidden;
	max-height: 9rem;
}

.nameCont {
	font-size: 1rem;
	color: #d9def4;
}

.title {
	font-size: 0.9rem;
	cursor: pointer;
	color: #d9def4;
}
.pageTitle {
	font-size: 1.2rem;
	font-weight: 400;
	color: #d9def4;
	margin: auto 0.5rem;
}

.name {
	margin: 0 0 1rem 0;
	font-size: 1rem;
	font-weight: 400;
}

.date {
	margin: 0;
	width: max-content;
	margin-left: 0.5rem;
	font-size: 0.78rem;
	color: #d9def4;
}

.buttonHeader {
	margin: 1rem 1rem 1rem 0;
	display: flex;
	align-items: center;
	transition: 250ms;
	cursor: pointer;
}

.buttonHeader .title {
	margin: 0;
	flex: 1;
	font-weight: 500;
	font-size: 1.25rem;
	color: #d9def4;
}

.mediaCont {
	margin: 0 auto;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.media {
	margin: 0.2rem auto;
	min-width: 8.5rem;
	width: 8.5rem;
	height: 8rem;
	display: flex;
	justify-content: center;
	transition: all 0.3s ease-in-out;
	outline: none;
	border: none;
	overflow: hidden;
	border-radius: 0.5rem;
}

.media img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
}

.username {
	font-size: 1rem;
	margin: 0 1rem 0;
}

.mediaButton {
	padding: 1rem;
	margin-top: 2rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-family: Roboto;
}

.leave {
	padding: 0.5rem 1rem;
	display: flex;
	text-transform: capitalize;
	font-size: 0.9rem !important;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-family: Roboto;
	margin-right: 0.5rem;
}

.leaveTitle {
	font-size: 0.9rem;
	margin: 0;
	font-weight: 400;
	text-transform: capitalize;
	margin-left: 8px;
	padding: 0;
}

.heading {
	padding: 0.5rem 0;
	display: flex;
}

.breadCrumb {
	margin: 1rem auto 0 auto;
	width: 90%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	border-bottom: 1px solid #ddd;
}

.selected {
	font-size: 0.9rem;
	color: #d9def4;
	cursor: pointer;
}

.closeBtn {
	cursor: pointer;
}

.no {
	margin: 0.5rem;
	font-size: 1rem;
	text-align: center;
}
.participantsList {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	flex: 1;
	overflow: auto;
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 3px;
	}
	.heading {
		align-items: center;
		padding-left: 0;
		z-index: 100;
		position: sticky;
		top: 0;
		font-size: 1.1rem;
	}
	.icon {
		width: 1.5rem;
		height: 1.5rem;
		margin-right: 1rem;
	}
}

.memberList {
	margin-left: -0.5rem;
	padding-bottom: 0.5rem;
}

@media only screen and (max-width: 768px) {
	.contShow {
		width: auto;
		// background-color: white;
		// height: 70vh;
		position: relative;
		padding: 0;
		padding: 10px 20px;
	}
	.topContainer {
		margin: 10px 0;
	}
	.nameCont {
		margin: 0;
	}
	.media {
		margin: 5px;
		min-width: 6rem;
		width: 6rem !important;
		height: 6rem !important;
	}
}
