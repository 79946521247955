.popover {
	padding: 0 1rem;
	font-family: "Poppins";
	justify-content: space-around;
	border-radius: 16px;
}

.infotext {
	margin-left: 1.5rem;
	display: flex;
	flex-direction: column;
}

.top {
	width: 20rem;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.bottom {
	margin-top: 1em;
	display: inline-flex;
	flex: 1;
	align-content: flex-start;
	justify-content: left;
}

.posts {
	display: block;
	font-style: normal;
	font-weight: normal;
	font-size: 0.75rem;
	line-height: 14px;
}

.username {
	margin: 1rem 0 0.5rem 0;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: normal;
	display: inline-flex;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.usernameText {
	font-size: 1.05rem !important;
	font-style: normal;
	font-weight: normal;
	width: 10rem;
	line-height: 19px;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: break-word;
	word-wrap: break-word;
}

.profession {
	margin-bottom: 0.5rem;
	display: block;
	color: #979797;
	font-style: normal;
	font-weight: normal;
	font-size: 0.9rem;
	line-height: 0.89rem;
}

.username:hover {
	cursor: pointer;
}

.posts1 {
	display: block;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
}

.buttons {
	margin-top: 0.58em;
	padding-top: 0.2em;
	margin-left: 2px;
	font-style: normal;
	font-weight: normal;
}

.friendBtn {
	margin: 1rem 0;
	display: flex;
	background: transparent;
}

.btns {
	justify-content: center;
	align-content: center;
	text-align: center;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 16px !important;
	color: #434343 !important;
	padding-left: 0.3rem !important;
	width: 50%;
	display: inline-flex;
}
.info {
	padding-left: 0.3rem !important;
	margin-top: 0.2rem;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #434343;
	width: 50%;
	display: inline-flex;
	border-right: 1px solid #dfdfdf;
}

.btn, .pinkBtn {
	margin: 0.25rem 0;
	padding: 0.1rem 0.2rem;
	font-size: 1rem;
	outline: none;
	border: none;
	border-radius: 4px;
	background: transparent;
	color: #555;
}

.btn:hover {
	color: #ff5b5b;
}

.pinkBtn {
	padding: 0.25rem 0.5rem;
	font-size: 0.9rem !important;
	background: transparent linear-gradient(71deg, #ff5b5b 0%, #dba3db 100%) 0% 0% no-repeat padding-box;
	color: #fff !important;
}

.darkPText {
	color: #d9def4;
}

.lgPText {
	color: #0d0f22;
}

.darkSText {
	color: #7f89be;
}

.lgSText {
	color: #4a517e;
}

.darkTText {
	color: #4a517e;
}

.lgTText {
	color: #7f89be;
}
