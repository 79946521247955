@import "./Themes/ThemeSelector.scss";

input{
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        @include themed() {
            box-shadow: 0 0 0 30px t(backgroud-color);
            -webkit-box-shadow: 0 0 0 30px t(backgroud-color);
            -webkit-text-fill-color: t(heading-color2);
        }
    }

}

