@import "../../../../../Themes/ThemeSelector.scss";

.link_color{
	@include themed() {
		color: t(text-dark);
	}
}

.ul {
	margin: 0 auto;
	padding: 0.25rem 1rem;
	display: flex;
	align-items: center;
	border-radius: 1rem;
	@include themed() {
		background: t(text-dark);
		box-shadow: 0px 0px 5px t(backgroud-color);
	}
	.li {
		list-style: none;
		width: 0.155rem;
		height: 35px;
		@include themed() {
			background: t(heading-color2);
		}
		margin: 0 3.75px;
		border-radius: 5px;
	}

	@for $i from 1 through 50 {
		.li-#{$i} {
			animation: move 0.95s ease-in-out $i * 0.2s infinite;
		}
	}

	@for $i from 1 through 50 {
		.li-stop-#{$i} {
			align-self: center;
			height: random(25) + 10 + px !important;
		}
	}
}
@keyframes move {
	0% {
		transform: scaleY(1);
	}
	10% {
		transform: scaleY(0.2);
	}
	20% {
		transform: scaleY(0.4);
	}
	30% {
		transform: scaleY(0.7);
	}
	40% {
		transform: scaleY(0.8);
	}
	50% {
		transform: scaleY(1);
	}
	60% {
		transform: scaleY(0.4);
	}
	70% {
		transform: scaleY(0.6);
	}
	80% {
		transform: scaleY(0.2);
	}
	90% {
		transform: scaleY(0.5);
	}
	100% {
		transform: scaleY(0);
	}
}
