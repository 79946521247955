.root {
	width: 25rem;
	height: 40rem;
}	

.section {
	width: 100%;
	position: relative;
}

.rootDark {
	background: #121528 !important;
}

.rootLg {
	background: #fff !important;
}

.sticky {
	z-index: 10;
}

.groupHeading {
	padding: 2rem 2rem 1rem 2rem;
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.headinglg {
	font-size: 1.25rem;
	color: #0d0f22 !important;
}

.headingdark {
	font-size: 1.25rem;
	color: #d9def4 !important;
}

.searchBox {
	margin: .5rem 2rem;
	padding: 0.5rem;
	display: flex;
	align-items: center;
	border: 1px solid #7f89be;
	border-radius: 0.5rem;
}

.searchField {
	width: 100%;
	font-size: 1rem;
	background: var(--backgroud-color);
	color: var(--secondary);
	border: none;
}

.groupCont {
	margin: 1rem 1rem;
	display: flex;
	flex-direction: column;
}

::-webkit-scrollbar{
	width: 0;
}

.item, .myGrp {
	margin: .5rem 0;
	padding: 0 0 0 1rem;
	width: 100%;
	display: flex;
	align-content: center;
	align-items: center;
}

.myGrp{
	padding: 0 0 0 1rem;
	border-radius: 5rem;
	border: 1px solid #00C6FF;
}

.cont {
	display: flex;
	width: 100%;
	align-content: center;
	align-items: center;
}

.gCont{
	margin: 1rem 0;
	display: flex;
	width: 100%;
	align-content: center;
	align-items: center;
}

.grpname {
	font-size: 1.25rem;
}

.moreIcon {
	display: flex;
}

.editPhoto img {
	margin-left: 0.75rem;
	width: 1.5rem;
}

.textlg {
	color: #0d0f22;
}

.textdark {
	color: #d9def4;
}

.header {
	padding: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header h2 {
	margin: 0;
	font-size: 1.25rem;
}

.dialog {
	padding-bottom: 30px;
	max-width: 400px !important;
	font-family: "Poppins";
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.textBox {
	margin: 1rem;
}

.textBox p {
	margin: 1rem 0;
	font-size: 14px;
}
