.popover {
	object-fit: contain;
	background: var(--dark-background);
}

.wrapper {
	position: absolute;
	bottom: 0;
	left: 0;
	display: inline-flex;
	width: 100%;
	background: var(--dark-background);
}
.wrapper img {
	object-fit: cover;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.info {
	padding: 1rem;
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-right: 1px solid #dfdfdf;
}

.polygon {
	position: absolute;
	width: 43px;
	height: 42px;
	margin-left: -50px;
	background: var(--dark-background);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	transform: rotate(-90deg);
}

.btn,
.pinkBtn {
	margin: 0.25rem 0;
	padding: 0.1rem 0.2rem;
	font-size: 1rem;
	outline: none;
	border: none;
	border-radius: 4px;
	background: transparent;
	color: #555;
}

.btn:hover {
	color: #ff5b5b;
}

.pinkBtn {
	padding: 0.25rem 0.5rem;
	font-size: 0.9rem !important;
	background: transparent linear-gradient(71deg, #ff5b5b 0%, #dba3db 100%) 0%
		0% no-repeat padding-box;
	color: #fff !important;
}
