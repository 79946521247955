.darkBody {
	color: #d9def4;
	background: #0d0f22;
}

.darklg {
	color: #0d0f22;
	background: #fff;
}

.heading {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.closeBtn {
	margin-right: 1rem;
}

.title {
	font-size: 21px;
	font-family: Avenir-Bold;
	margin: 0 0 1rem 2rem;
}

.image1 {
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
	border: 0.5px solid #4f4f4f;
	box-sizing: border-box;
	border-radius: 6px;
}
.image {
	width: 100%;
	min-height: 10rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.currentImage {
	border-radius: 0.4rem;
	margin-bottom: 1rem;
	max-width: 40rem;
	max-height: 25rem;
	/* transform: scale(0.7); */
}
@media screen and (max-width: 680px) {
	.image1 {
		margin-right: 1.1%;
		margin-bottom: 1.7%;
		margin-left: 74%;
	}
}
