.container {
	padding: 1rem;
	width: 22%;
	display: flex;
	flex-direction: column;
	scrollbar-width: none;
}

.grpContainer {
	margin-top: 2.5rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--backgroud-color);
}

.groupName {
	width: 100%;
	display: flex;
	align-items: center;
}

.grpname {
	margin: 0 1.7rem 0 0.4rem;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
}

.textlg {
	color: #0d0f22;
}

.textDark {
	color: #d9def4;
}

.notConnected {
	width: 100%;
	/* height: 200px; */
	background-color: #ff5b5b;
	display: flex;
	align-items: center;
}

.notConnectedContent {
	flex: 1;
	padding: 1rem 0.5rem 1rem;
	color: white;
}
.notConnectedContent h3 {
	font-size: 1.1rem;
	margin-bottom: 0;
}
.notConnected p {
	margin: 0;
	font-size: 0.9rem;
}

.container::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.showContainer {
	padding: 1rem;
	width: 22%;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	scrollbar-width: none;
}

.popoverBtn {
	display: flex;
	flex-direction: column;
	justify-content: left;
}

.smallText {
	margin: 0;
	font-size: 0.7rem;
}

.searchContainer {
	margin: auto;
	margin-bottom: 1rem;
	padding: 0.5rem;
	width: 93%;
	display: flex;
	align-items: center;
	transition: 200ms cubic-bezier(0.56, 0.04, 0.33, 1.26);
	border: 1px solid #7f89be;
	box-sizing: border-box;
	border-radius: 8px;
}

.searchContainer .searchIcon {
	color: #7f89be;
}

.thoughtsTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #7f89be;
	margin: 10px 0 15px 0;
	border-radius: 9px;
}

.activeMobTab {
	background: linear-gradient(92.12deg, #00c6ff, #0072ff);
	/* background: #4a517e; */
	color: #fff;
	border-radius: 9px;
	padding: 8px;
	width: 100%;
	text-align: center;
}

.mobtab {
	padding: 8px;
	width: 100%;
	text-align: center;
}

.search {
	font-size: 0.75rem;
	border: 0;
	width: 90%;
	flex: 1;
	font-family: "Poppins";
	color: #7f89be;
	font-weight: 400;
	background-color: transparent;
}
.search:disabled {
	background-color: transparent;
}

.search::placeholder {
	color: #7f89be;
}
.topBar {
	margin: 1rem 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navigation {
	flex-grow: 1;
	height: 50px;
	display: flex;
}
.navigation .tabButton {
	background: none;
	border: none;
	padding: 0 1rem 0 10px;
	text-align: left;
	font-family: roboto;
	font-weight: 700;
	color: #7f89be;
	font-size: 1rem;
	font-size: normal;
	font-family: "Poppins";
	font-weight: 400;
}
/* .firstButton::after {
  content: "";
  top: 30%;
  height: 40%;
  right: 0;
  position: absolute;
  transform: rotate(25deg);
  width: 2px;
  background-color: #cccccc;
} */

.underline {
	margin-top: 0.25rem !important;
	width: 3rem;
	height: 3px;
	background: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%);
}

.activeTab {
	background: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%) !important;
	background-clip: text !important;
}

.convListLG,
.convListDk {
	padding: 0 10px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	flex: 1;
	z-index: 99999;
}

.convListLG::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.convListLG::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
	border: 0 !important;
}
.convListDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}
.convListDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}

@media only screen and (max-width: 768px) {
	.container {
		display: none;
	}

	.showContainer {
		width: 100%;
		padding: 0 1rem;
	}

	.mobThoughts {
		height: 70vh;
		overflow: auto;
		padding-bottom: 5%;
	}

	.convListLG,
	.convListDk {
		height: 70vh;
		overflow-y: auto;
	}

	.navigation {
		height: auto;
	}
	.topBar {
		margin: 0;
	}
	.searchContainer {
		width: 100%;
	}
}
