.container {
	margin: 0 auto;
	padding: 1rem 2rem 1rem 5rem;
	width: 100%;
	overflow: hidden;
	overflow-y: hidden;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	height: 5rem;
	left: 0;
	font-family: Metropolis-Medium;
	background: #fff;
	z-index: 10000;
}

.mainCont {
	width: 10%;
}

.close,
.popup {
	width: 70%;
	display: flex;
	justify-content: space-between;
}

.brand {
	margin: 0;
	width: 6rem;
}

.brand img {
	width: 100%;
	height: 100%;
}

.options {
	margin: 0;
	padding: 0;
	width: 65%;
	list-style: none;
	display: flex;
	align-items: center;
}
.options li {
	flex-grow: 1;
	height: 100%;
	/* width: 22%; */
}

.optionBtn {
	flex: 1;
	width: 100%;
	cursor: pointer;
	height: 100%;
	/* padding: 0 1.5rem; */
	text-align: left;
	margin: 0 0.5rem;
	color: #333 !important;
	transition: all 0.1s ease;
}
.active {
	border-bottom: 2px solid #2856c8 !important;
}

.optionBtn:hover {
	color: #ff5b5b;
}

.btns {
	display: flex;
	align-items: baseline;
}

.signInBtn {
	margin: 0 0.5rem;
	outline: none;
	border: none;
	background: transparent;
	transition: all 0.1s ease;
}

.signInBtn:hover {
	color: #ff5b5b;
}

.signUpBtn {
	margin: 0 0.25rem;
	padding: 0.25rem 1rem;
	outline: none;
	border: none;
	border-radius: 10px;
	color: #fff;
	background: linear-gradient(45deg, #ff5b5b 0%, #dba3db 100%);
}

.signUpBtn:hover {
	opacity: 0.8;
}

.mobNavBtn {
	display: none;
}

@media screen and (max-width: 900px) {
	.firstContainer {
		height: auto;
	}

	.brand {
		width: 5rem;
	}
	.container {
		padding: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-shadow: 0px 2px 20px #00000026;
		overflow: hidden;
	}

	.mainCont {
		padding: 0.75rem 1rem;
		width: 100%;
		display: flex;
		justify-content: space-between;
		box-shadow: 0px 2px 20px #00000026;
	}

	.close {
		display: none;
	}

	.popup {
		margin: 0;
		width: 100%;
		height: 92vh;
		top: 4.6rem;
		position: fixed;
		background-color: white;
		z-index: 100;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.options {
		margin: 2rem;
		padding: 0;
		height: 25%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}

	.btns {
		margin-bottom: 5%;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;

		margin-bottom: 200px;
	}

	.signInBtn,
	.signUpBtn {
		margin: 0.25rem;
		padding: 0.5rem 1.5rem;
		width: 10rem;
	}

	.mobNavBtn {
		margin-left: 0 0 0 auto;
		font-size: 2rem;
		display: block;
		cursor: pointer;
	}
}
