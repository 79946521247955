.sidebar {
	width: 20%;
	height: 100%;
	/* border: 1px solid red; */
	display: flex;
}

.contentContainer {
	flex: 1;
}

/* starts here */
.wrapper {
	display: flex;
	padding: 0;
	box-sizing: border-box;
	height: 100%;
	margin: 0 auto;
	justify-content: center;
	font-family: Metropolis-Medium;
}

.wrapper::before {
	content: "";
	display: inline-block;
	vertical-align: middle;
}

.container {
	width: 100rem;
	height: 91.2vh;
	overflow: hidden;
	display: flex;
	flex-direction: row;
}
.container::-webkit-scrollbar {
	display: none;
}

@media screen and (max-width: 768px) {
	.container {
		padding: 0;
		flex: 1;
		width: 100%;
	}

	.sidebar {
		width: 100vw;
	}
}
