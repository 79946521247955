.container {
	margin: 0 auto;
	padding: 5rem 7rem;
	width: 100%;
	position: relative;
	overflow: hidden;
	overflow-y: hidden;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	font-family: Metropolis-Medium;
	color: #8c97ac;
	background: linear-gradient(84.45deg, #0b0d17 0%, #1f2959 106.63%);
}

.yellowCircle {
	height: 20rem;
	width: 20rem;
	position: absolute;
	top: -5rem;
	left: -12rem;
	background: linear-gradient(221.07deg, #ffd166 24.95%, #ff8266 83.76%);
	border-radius: 50%;
}

.cont {
	display: flex;
}

.info {
	width: 40%;
}

.brand {
	font-size: 4rem;
	color: #fff;
	max-width: 7rem;
}

.brand img {
	width: 100%;
}

.options,
.options1 {
	display: flex;
}

.class {
	font-size: 1.2rem;
}

.option {
	margin: 0 2rem;
	max-width: 30%;
}

@media only screen and (max-width: 880px) {
	.links {
		margin: 0 0 1rem -2.4rem !important;
	}
}

.links {
	margin: 0 0 1rem -2.42rem;
	list-style: none;
	font-size: 1rem;
	color: #e9f7fe;
}

.links > a {
	color: #e9f7fe;
}

.links > a:hover,
.links:hover {
	color: #ffd166;
}

.footer {
	margin: 0 auto;
	padding: 1rem 7rem 1rem 9rem;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	color: #e9f7fe;
	background: linear-gradient(84.45deg, #0b0d17 0%, #1f2959 106.63%);
}

.copy {
	margin: 0;
	font-size: 0.8rem;
}

.icons {
	width: 20%;
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width: 900px) {
	.container {
		padding: 5rem 0 0 0;
	}
	.cont {
		display: flex;
		flex-direction: column;
	}

	.info {
		padding: 0 2rem;
		width: 75%;
	}

	.options {
		width: 100%;
		display: flex;
	}

	.options1 {
		width: 50%;
		display: flex;
		flex-direction: column;
	}

	.option {
		max-width: 100%;
	}

	.links {
		width: 100%;
		margin: 1rem 0 1rem -2rem;
	}

	.links > a {
		color: #fff;
	}

	.footer {
		padding: 2rem 0 0 2rem;
		width: 100%;
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-around;
		align-items: stretch;
	}

	.icons {
		padding: 1rem;
		width: 40%;
		display: flex;
	}

	.copy {
		padding: 0.5rem 0 0 1rem;
		font-size: 1.1rem;
		z-index: 2;
	}

	.yellowCircle {
		height: 20rem;
		width: 20rem;
		position: absolute;
		top: -0.5rem;
		left: 14rem;
		background: linear-gradient(221.07deg, #ffd166 24.95%, #ff8266 83.76%);
		border-radius: 50%;
		z-index: 1;
	}
	.copy {
		font-size: 0.7rem;
		padding: 0 0 2rem 0;
	}
}
