@import "../../../../Themes/ThemeSelector.scss";

.subHead h2 {
	@include themed() {
		color: t(text-primary);
	}
}

.addContainer {
	@include themed() {
		background: t(backgroud-color);
	}
}

.searchBox {
	@include themed() {
		background: t(backgroud-color);
		border: 1px solid t(secondary);
	}
}

.searchField {
	@include themed() {
		background: t(backgroud-color);
		color: t(secondary);
	}
}

input::placeholder {
	@include themed() {
		color: t(secondary);
	}
}
