.auth-page-Container {
	position: relative;
	overflow: hidden;

	.yellowDiv {
		position: absolute;
		width: 550px;
		height: 425px;
		left: -17rem;
		z-index: -1;
		bottom: -9rem;
		background: linear-gradient(
			180.57deg,
			#ffd166 0.49%,
			rgba(255, 130, 102, 0) 147.16%
		);
		border-radius: 46px;
		transform: rotate(124.02deg);
	}

	.pinkOval {
		position: absolute;
		width: 542px;
		height: 542px;
		right: -18rem;
		top: -10rem;
		z-index: -1;
		border-radius: 50%;
		background: linear-gradient(
			215.64deg,
			#fce9e9 -23.48%,
			#fbcece -0.64%,
			#fbdfdf 3.73%,
			#f89f9f 85.69%
		);
	}
	.container {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		min-height: 100vh;
		overflow-y: auto;
		&::-webkit-scrollbar {
			display: none;
		}
		.form-container {
			padding: 1rem;
			display: flex;
			flex-direction: column;
			border-radius: 5px;
		}
	}
}
@media screen and (max-width: 768px) {
	.auth-page-Container {
		.logo {
			padding: 1rem 1.5rem;
		}
		.pinkOval {
			top: -20rem;
			right: -25rem;
		}
		.yellowDiv {
			width: 450;
			height: 325px;
			bottom: -12rem;
			left: -22rem;
		}
		.container {
			// margin-top: 5rem;
		}
	}
}
