.rootDark {
	background: #121528 !important;
}

.rootLg {
	background: #fff !important;
}

.lgText {
	color: #0d0f22;
}

.darkText {
	color: #d9def4;
}

.wrapper {
	display: flex;
	margin: 0;
	padding-left: 0.2rem;
	box-sizing: border-box;
}

.wrapper::before {
	content: "";
	display: inline-block;
	vertical-align: middle;
}
.rightWrapper {
	width: 80%;
	margin: 0.9em auto;
	padding-left: 0.5%;
}
.rightFaqWrapper {
	width: 80%;
	margin: -0.5em auto;
	padding-left: 1.5%;
}
.container {
	margin: auto;
	width: 100rem;
	overflow: hidden;
	overflow-y: hidden;
	/* border: 1px solid #F2F2F2; */
	display: flex;
	flex-direction: column;
	height: 90.9vh;
	font-family: "Poppins";
}

.header {
	margin: 0 auto;
	padding: 4rem 0 2rem 0;
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.main {
	display: flex;
}

.MuiAccordion-root:before {
	top: -1px;
	left: 0;
	right: 0;
	height: 0px !important;
	content: "";
	opacity: 0 !important;
	position: absolute;
	transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	background-color: rgba(255, 255, 255, 0.12);
}
.left {
	/* display: flex; */
	padding: 0 1rem;
	width: 20%;
}

.sidebar {
	height: 100vh;
	width: 100%;
	padding: 0;
	list-style: none;
	overflow: hidden;
}

.sidebar::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.link {
	padding: 1.4rem 1.3rem;
	width: 100%;
	color: var(--secondary);
	opacity: 0.99;
	font-size: 14px;
	font-weight: 500;
	box-sizing: border-box;
	border: 8px;
	/* border-left: 0.3rem solid transparent;
    border-right: 0.3rem solid transparent; */
	cursor: pointer;
}

.link:hover {
	/* color: #fd5d5f;
    opacity: 1;
    border-left: 0.3rem solid #fd5d5f;
    border-right: 0.3rem solid #fd5d5f; */
}

.selected {
	padding: 1rem;
	width: 100%;
	font-size: 14px;
	color: var(--heading-color2);
	opacity: 1;
	background: var(--button-gradient3);
	border-radius: 8px;
	/* border-left: 0.3rem solid #fd5d5f; */
	cursor: pointer;
}

.right {
	margin: 0 auto;
	padding-left: 2rem;
	scrollbar-width: none;
	flex-grow: 1;
	height: 100vh;
	position: static;
	top: 0;
	overflow-y: scroll;
	overflow-x: hidden;
}

.right::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.right > * {
	margin-bottom: 30%;
	/* padding-right: 8%; */
	/* color: rgba(0, 0, 0, 0.5); */
}

.heading {
	font-weight: 700;
	font-size: 21px;
	color: #ff6c5c;
	display: inline-flex;
	margin-bottom: 1em;
}

.content {
	font-size: 1.1rem;
}

.iconImg {
	width: 1rem;
	height: 1rem;
	margin-right: 0.5rem;
}

@media screen and (max-width: 768px) {
	.activeTab {
		text-align: center;
	}
	.tab {
		text-align: center;
	}
	.settingTab {
		display: grid !important;
		grid-template-columns: 50% 50% !important;
		/* margin: 20px; */
	}
	.header {
		color: #fd5d5f;
		margin: 1.2rem 1.2rem 1.5rem 0.5rem;
		display: flex;
		/* justify-content: space-between; */
		/* align-items: center; */
	}

	.header h2 {
		margin: 2px 0 0 1.2rem;
		flex: 1;
		font-size: 1.5rem;
		font-weight: 500;
	}
	.wrapper {
		height: 100%;
		padding: 0;
		margin: 0;
		padding-left: 0%;
	}
	.ShareSocial {
		width: 100%;
	}
	.container {
		height: 100%;
		overflow: auto;
		border: none;
		padding: 0;
		margin: 0;
	}
	.blank {
		display: none;
	}

	.arrowBtn {
		margin: 1rem;
		color: #ff6c5c !important;
	}

	.right {
		margin: 0;
		width: 100%;
		padding-left: 0rem;
	}
	.left {
		margin: 0;
	}
	.sidebar {
		margin: 0;

		width: 100%;
		height: auto;
	}

	.link {
		margin: 1rem 0;
		padding-bottom: 1rem;
		padding-top: 0.5rem;
		width: 100%;
		font-size: 1.3rem;
		border-bottom: 1px solid #f2f2f2;
	}
	.selected {
		margin: 1rem 0;
		padding-bottom: 1rem;
		padding-top: 0.5rem;
		width: 100%;
		font-size: 1.3rem;
		border-bottom: 1px solid #f2f2f2;
		border-right: none;
	}
	.iconright {
		margin-left: auto !important;
	}

	.heading {
		margin: 2rem;
		color: #fd5d5f;
		opacity: 1;
		font-size: 1.25rem;
	}

	.content {
		margin: 0rem 2rem;
		color: rgb(0, 0, 0);
		/* opacity: 0.99; */
		font-size: 1.2rem;
	}

	.btn {
		/* flex: 1; */
		width: 90%;
		margin-left: 5%;
		align-self: center;
	}

	.form {
		margin: 0 1rem 0 2rem;
		padding-right: 0;
	}

	.divStyle {
		display: flex;
		flex-direction: column;
	}

	.divStyle > * {
		margin: 1rem 0;
	}

	.valueCont {
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.valueHead {
		width: 100%;
	}

	.values {
		width: 100%;
	}

	.value {
		margin: 0 2rem;
	}

	.imgCont {
		margin: 1rem;
		width: 50%;
	}

	.img {
		width: 100%;
		height: 10rem;
	}

	.contact {
		margin: 1rem 2rem;
		display: flex;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 4rem;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}

	.tutorialButtonContainer {
		height: 100%;
		display: flex;
	}
	.tutorialLink {
		display: flex;
		align-items: center;
	}
}

.link,
.selected,
.linkSub,
.selectedSub {
	list-style: none;
}

.link,
.selected {
	padding: 1rem 1rem;
	width: 100%;
	opacity: 0.99;
	font-size: 1rem;
	box-sizing: border-box;
	display: flex;
	align-items: center;
}

.selectedSub,
.linkSub {
	padding: 0.5rem 1rem;
	width: 100%;
	opacity: 0.99;
	font-size: 1rem;
	box-sizing: border-box;
	display: flex;
	align-items: center;
}

.link {
	color: #7f89be;
}

.linkSub {
	color: #7f89be;
}

.selected {
	color: #fff;
	opacity: 1;
	border-radius: 8px;
	background: linear-gradient(92.12deg, #00c6ff 0%, #0072ff 100%);
}

.imgIcon {
	margin-right: 0.5rem;
	width: 1.5rem;
	height: 1.5rem;
}

.selectedSub {
	color: #fff;
	opacity: 1;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.settingTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	color: #7f89be;
	margin: 20px;
	border-radius: 9px;
	width: 90%;
}

.activeTab {
	/* background: #4a517e; */
	background: linear-gradient(92.12deg, #00c6ff, #0072ff);
	color: #fff;
	border-radius: 9px;
	padding: 8px 40px;
}

.tab {
	padding: 10px 40px;
}
