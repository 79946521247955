.title {
	/* color: #ff5b5b; */

	font-family: inter;
	font-weight: 700;
	font-size: 1.8rem;
}

.message {
	margin: 0;
	width: 100%;
	color: rgba(134, 146, 166, 1);
	font-size: 0.9rem;
	font-family: inter;
}

.btns{
	margin: 2rem 0 1rem 0;
	display: flex;
	justify-content: flex-end;
}

.button {
	margin-top: 1.2rem;
	padding: 0.5em;
	color: white;
	/* background: transparent linear-gradient(252deg
, #5BCEFF 0%, #5B6CFF 100%) 0% 0% no-repeat padding-box; */
	background: linear-gradient(225deg, #ff5b5b 0%, #fc4249 100%);
	border: none !important;
	border-radius: 8px !important;
}

.link {
	padding-top: 1rem;
	text-align: center;
	color: #888888;
	font-size: 0.8rem;
}
