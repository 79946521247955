.likedMessage {
	/* margin-right: 0.5rem; */
	margin-bottom: 1rem;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1;
	position: relative;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	border-radius: 0px 32px 32px 32px;
}

.chat {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-left: 3px;
}

.nameavatar {
	width: 100%;
	display: flex;
	color: #7f89be;
	font-weight: 500;
	font-style: normal;
	font-style: 1.25rem;
	font-family: "Popppins";
	justify-content: space-between;
	align-items: center;
}

.author {
	font-size: 0.8rem;
	font-weight: 400;
	color: #4a517e;
	font-family: "Poppins";
	display: flex;
	align-items: center;
	margin-left: 8px;
	overflow-wrap: anywhere;
}

.menu {
	justify-self: flex-end;
}

.messageCont {
	margin: 0 0.5rem 0 0.2rem;
	/* padding: 0 0.5rem; */
	display: flex;
	flex-direction: column;
	/* border-radius: 6px; */
	font-size: 1rem;
	background-color: transparent !important;
	position: relative;
}

.username {
	margin: 0;
	font-size: 0.9rem;
	color: #999;
}

.message {
	margin: 0.25rem 0;
	margin: 0;
	padding-top: 0.7rem;
	padding-bottom: 0.313rem;

	max-height: 50rem;

	overflow-y: hidden;

	flex: 1;
	display: flex;
	flex-direction: column;

	color: #0d0f22;
	font-weight: 400;
	font-style: normal;
	font-size: 12px;
	font-family: "Poppins";
}
.linkColor {
	color: red;
}

.imgCont {
	width: 100%;
	min-height: 3rem;
}
.img {
	margin: 0.5rem 0;
	object-fit: cover;
	overflow: hidden;
	min-width: 100%;
	width: 100%;
	border-radius: 4px;
	max-height: 10rem;
}

.fileCont {
	width: 100%;
	border: 1px solid #eee;
	border-radius: 7px;
	display: flex;
	margin-top: 5px;
	align-items: center;
	padding: 5px;
}

.fileCont p {
	flex: 1;
	margin: 0;
	color: #999;
}

.replyCont {
	margin: 0.5rem 0 0 0;
	padding: 0.25rem 0.5rem;
	background: #dfdfdf;
	border-radius: 4px;
}

.videoMessageCont {
	margin: 0.5rem 0;
	width: 15rem;
	position: relative;
	border-radius: 4px;
}

.videoCont {
	height: 100%;
	position: relative;
}

.shadeCont {
	width: 100%;
	height: 95%;
	position: absolute;
	top: 0;
	background: #00000056;
}

.video {
	width: 100%;
	max-width: 15rem;
	border-radius: 4px;
}
.date {
	/* margin: 0 0 0.25rem auto; */
	/* justify-content: flex-end; */
	/* align-self: flex-end; */
	font-size: 0.625rem;
	color: #7f89be;
	font-weight: 400;
	font-style: normal;
	margin: 5px 0;
}

@media only screen and (max-width: 768px) {
	.likedMessage {
		background: #0d0f22 !important;
	}
}
