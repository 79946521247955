.chat {
	display: flex;
	flex-direction: row;
	margin: 0.15rem auto 0.15rem 0;
	min-width: 50%;
	max-width: 60%;
	border-top-right-radius: 32px;
	border-bottom-right-radius: 32px;
	border-bottom-left-radius: 32px;
}
.myChat {
	margin: 0 0 0.3rem 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	/* background-color: #4a517e; */
	max-width: 50%;
	border-top-left-radius: 32px;
	border-bottom-right-radius: 32px;
	border-bottom-left-radius: 32px;
	max-width: 60%;
	float: right;
	margin-right: 10px;
}

.chatContent {
	padding: 1rem;
	min-width: 18rem;
	align-items: end;
	justify-content: flex-start;
	border-top-right-radius: 32px;
	border-bottom-right-radius: 32px;
	border-bottom-left-radius: 32px;
}

.nameavatar {
	margin-bottom: 0.5rem;
	display: flex;
	flex-direction: row;
}
.nameavatar div:nth-child(2) {
	flex: 1;
}
.forwardedTag {
	font-style: italic;
	font-size: 0.8rem;
	color: #888;
	margin-bottom: 0;
}

.maxWidthContent {
	width: 60%;
}

.myChatContent {
	width: 100%;
	padding: 0.5rem 1rem;
	display: flex;
	flex-direction: row-reverse;
}

.midCont {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	position: relative;
}

.deletedText {
	padding: 5px 15px;
	font-style: italic;
	font-size: 0.9rem;
	margin: 0;
}

.revMidCont {
	margin: 0 auto;
	display: flex;
	max-width: 100%;
	flex-direction: column;
	align-items: flex-end;
	position: relative;
}

.lastinGroup {
	margin-bottom: 1rem;
}
.messageCont {
	position: relative;
	font-size: 1rem;
	width: 100%;
	overflow-wrap: break-word;
	transition: 200ms;
	background-color: transparent;
}
/* .messageCont:hover .replyOptions {
  display: block;
} */
.myChat:hover .replyOptionsMy {
	opacity: 1;
}
.chatContent:hover .replyOptions {
	opacity: 1;
	/* display: flex; */
}
.replyoption {
	margin: 0.25rem 0;
}
.show_reply_icon {
	cursor: pointer;
	flex-basis: 30%;
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.replyOptions {
	/* margin: 4px; */
	opacity: 0;
	display: flex;
	/* position: absolute; */
	/* top: 0; */
	/* right: 0; */
	/* width: 20px; */

	align-items: center;
}
.replyOptionsMy {
	opacity: 0;
	align-items: center;
}

.highlighted {
	padding: 0.25rem 0.5rem;
	opacity: 0.75;
	border: 1px solid #e42a5b;
	border-radius: 0.5rem;
}

.username {
	margin: 0;
	font-size: 0.625rem;
	color: #7f89be;
	font-weight: 400;
	font-style: normal;
	font-family: "Poppins";
	padding-left: 12px;
	padding-top: 10px;
}
.myUsername {
	margin: 0;
	font-size: 0.625rem;
	color: #d9def4;
	font-weight: 400;
	font-style: normal;
	font-family: "Poppins";
	padding-left: 12px;
	padding-top: 10px;
}

.stats {
	/* border: 1px solid red; */
	margin: 0 0 0 2.5rem;
	display: flex;
	align-items: flex-end;
}
.statsItem {
	margin: 5px 10px 0 10px;
	display: flex;
	align-items: center;
}
.statsItem:nth-child(2) {
	margin-left: 0;
}

.statsItem span {
	margin-left: 3px;
}

.myStats {
	align-self: flex-end;
	margin: 0 2.5rem 0 0;
}

@media screen and (max-width: 768px) {
	.myChatContent {
		max-width: 90%;
	}
	.myChat {
		max-width: 85%;
	}
	.chatContent {
		max-width: 90%;
	}
	.maxWidthContent {
		width: 90%;
	}
	.infoChat {
		max-width: 90%;
	}
}
