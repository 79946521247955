@import "../auth.scss";
@import "../../../Themes/ThemeSelector.scss";

.auth-page-Container {
	.container {
		.forget-form-container {
			.headerN {
				margin: 0 auto;
				margin-top: 10rem;
				width: 100%;
				display: flex;
				flex-direction: column;
				text-align: center !important;
				h1 {
					font-size: 4.5rem;
					@include themed() {
						color: t(heading-color1) !important;
					}
				}

				p {
					align-self: center;
					width: 40rem;
					@include themed() {
						color: t(secondary) !important;
					}
				}
			}

			.forgot-form {
				// border: 1px solid red;
				display: flex;
				padding: 0 4rem;
				form {
					width: 100%;
					display: flex;
					flex-direction: column;
				}

				.password-field {
					padding-bottom: 0;
				}
				.forgot-password {
					display: flex;
					padding-top: 0.7rem;
					justify-content: flex-end;
					a {
						font-size: 0.9rem;
						font-weight: 500;
					}
				}
				.login-btn {
					padding-top: 1rem;
					font-size: 0.9rem;
					button {
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.loader {
						height: 100%;
						margin-left: 10px;
					}
					.MuiCircularProgress-colorPrimary {
						color: white !important;
					}
				}

				.no-account {
					padding: 1rem 0;
					p {
						text-align: center;
						font-weight: 400;
						font-size: 0.9rem;
						a {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.auth-page-Container {
		.container {
			.forget-form-container {
				padding: 1rem;
				width: 100%;
				.forgot-form,
				.headerN {
					h1 {
						font-size: 2.5rem;
						@include themed() {
							color: t(heading-color1) !important;
						}
					}

					p {
						align-self: center;
						@include themed() {
							color: t(secondary) !important;
						}
					}
				}
			}
		}
	}
}

/* Styling For Mobile View */
