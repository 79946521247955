.wrapper {
	display: flex;
	position: relative;
	height: 100%;
	background-color: #121528;
}

.wrapper::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.container {
	margin: auto;
	padding: 0;
	width: 100rem;
	height: 91.5vh;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	position: relative;
}

::-webkit-scrollbar {
	width: 0;
}

.body {
	display: flex;
}

.header {
	margin: 2rem auto 0 1rem;
	width: 98%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	position: relative;
}

.group {
	position: absolute;
	top: 13.5%;
	left: 0.75rem;
	width: 18%;
	height: 100%;
	z-index: 10;
}

.grplg {
	background: #fff;
}

.grpdark {
	background: #111527;
	width: max-content;
}

.grpContainer {
	margin: 0;
	width: 16rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.groupName {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.groupName p {
	margin: 0;
}

.grpname {
	margin-left: 0.5rem;
	font-size: 1rem;
}

.leftDk,
.leftLg {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-y: auto;
	max-height: 75vh;
	width: 85%;
	scrollbar-width: none;
	margin-top: 3rem;
}

.left::-webkit-scrollbar {
	width: 0;
	background: transparent;
}
.leftDk::-webkit-scrollbar-track {
	background: #2e2e48 !important;
}
.leftDk::-webkit-scrollbar-thumb {
	background: #fff !important;
}
.leftLg::-webkit-scrollbar-track {
	background: #d9def4 !important;
}
.leftLg::-webkit-scrollbar-thumb {
	background: #0d0f22 !important;
}

.heading {
	margin: 1rem 0rem 2rem 2rem;
	display: flex;
}

.headItem {
	display: flex;
	cursor: pointer;
	flex-direction: row;
}

.headItem h1 {
	font-weight: 500;
	font-size: 1.5rem;
	color: #cccccc;
}

.breadActive {
	font-size: 1.25rem !important;
}

.card {
	margin-right: 1rem;
}

.cards {
	margin: 0 auto;
	width: 96%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	/* overflow: hidden; */
	overflow-y: auto;
}

.cards::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.rowContainer {
	margin: 1rem 2rem;
}

.darkrow {
	color: #d9def4 !important;
}

.lgrow {
	color: #0d0f22;
}

.row {
	/* margin-left: 1rem; */
	display: flex;
	overflow-x: scroll;
	min-height: 14rem;
}

.row::-webkit-scrollbar {
	display: none;
}

.rowHead {
	display: flex;
	justify-content: space-between;
}

.rowHeadText {
	display: flex;
	padding-bottom: 1rem;
}

.rowArrowLeft {
	position: relative;
	top: -130px;
	left: -20px;
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
}

.rowArrow {
	position: relative;
	top: -130px;
	right: -20px;
	display: flex;
	align-items: center;
	justify-content: end;
	width: 100%;
}

.text {
	font-size: 1.25rem;
	margin-left: 10%;
}

.viewAll {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.viewAll p {
	cursor: pointer;
	text-transform: none;
	font-size: 0.75rem;
	font-weight: 400;
	color: transparent;
	background-clip: text;
	background: linear-gradient(42.12deg, #fc466b 30%, #3f5efb 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@media screen and (max-width: 768px) {
	.left {
		width: 100%;
	}
	.wrapper {
		padding-left: 0;
		height: 100%;
	}

	.heading {
		margin: 0.5rem 0 1rem;

		width: 100%;
		display: flex;
		justify-content: space-evenly;
		border-bottom: 1px solid #ccc;
	}

	.headItem {
		padding: 0 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-right: 1px solid #ccc;
	}
	.headItem h1 {
		font-size: 1.1rem;
	}

	#lasth1 {
		border-right: none;
	}

	.cards {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.card {
		width: 40%;
	}
	.container {
		height: 100%;
		width: 100vw;
	}
}

/* for tab section  */

.exploreTab {
	background: #030303;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	color: #7f89be;
	margin: 20px;
	border-radius: 9px;
	width: 90%;
}

.activeTab {
	background: #4a517e;
	color: #fff;
	border-radius: 9px;
	padding: 8px 10px;
}

.tab {
	padding: 10px 10px;
}

.para {
	height: 10vh;
	margin: 10px 25px 40px 25px;
	color: #d9def4;
}
