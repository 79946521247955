.wrapper {
	display: flex;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Metropolis-Medium;
}

.wrapper::before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.container {
	margin: 0 auto;
	width: 100rem;
	position: relative;
	overflow: hidden;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
}
@media screen and (min-width: 1100px) {
	.animationCreate {
		transform: translateY("30vh", "0vh");
		transition: transform 1.5s;
	}
}
@media screen and (min-width: 1100px) {
	.ourCulture {
		padding-right: 3.5em;
		padding-bottom: 3em;
		font-size: 3.1rem;
		font-family: Avenir-Bold;
		line-height: 1.4;
		/* animation: subHeadingAnimate 2.5s; */
	}
	.cultureScroll {
		/* overflow: hidden; */
	}
	.article > .aside {
		position: sticky;
		top: 0;
		width: 50%;
		height: 55vh;
		display: inline-flex;
		justify-content: top;
		align-items: top;
		flex-direction: row;
		/* margin: 2rem 0; */
		font-family: Avenir-Bold;
		overflow: hidden;
		/* padding: 0 2rem; */
		font-size: 3rem;
	}

	.valuesScroll {
		margin-left: 58%;
		height: 24vh;
	}
	.valuesScrollOne {
		margin-top: -55vh;
		margin-left: 58%;
		height: 24vh;
	}
	.partOfTeam {
		margin-top: 0.5em;
		font-size: 2.6em;
		font-family: Avenir-Bold;
	}
}

.cont {
	margin: 5rem 8rem;
}

.pinkCircle {
	height: 30rem;
	width: 30rem;
	position: absolute;
	top: 20rem;
	right: -20rem;
	background: linear-gradient(
		215.64deg,
		#fce9e9 -23.48%,
		#fbcece -0.64%,
		#fbdfdf 3.73%,
		#f89f9f 85.69%
	);
	border-radius: 50%;
	z-index: -1;
}

.blueBox {
	width: 15rem;
	height: 25rem;
	position: absolute;
	bottom: 14rem;
	right: -10rem;
	background: linear-gradient(57.46deg, #5bceff 0.1%, #5b6cff 71.51%);
	transform: rotateZ(-20deg);
	border-radius: 3rem;
	z-index: 10;
}

.heading {
	margin: 2rem 0;
	font-family: Avenir-Bold;
	font-size: 3rem;
}
.first {
	padding-top: 2rem;
}

.subHeading {
	margin: 2rem 0;
	font-family: Avenir-Bold;
	padding: 0 2rem;
	font-size: 3rem;
}

@keyframes subHeadingAnimate {
	from {
		padding-top: 300px;
		opacity: 0.45;
		line-height: 100px;
	}
	to {
		padding-top: 0;
		opacity: 1;
		line-height: 55px;
	}
}

.subHeadingAnimate {
	/* animation: subHeadingAnimate 1.5s; */
	margin: 2rem 0;
	font-family: Avenir-Bold;
	padding: 0 2rem;
	font-size: 3rem;
}

.content {
	margin-bottom: 2rem;
	margin-right: 9rem;
	text-align: justify;
	font-size: 1rem;
	color: #828282;
}

.valueCont {
	margin: 7rem 0;
	display: flex;
	justify-content: space-between;
}

.valueHead {
	width: 40%;
	position: sticky;
	top: 0;
}

.valueHead .subHeading {
}
.values {
	width: 40%;
	padding-right: 1.5rem;
}

.value {
	font-size: 1.5rem;
	color: #2856c8;
}

.team {
	position: relative;
}

.yellowBox {
	width: 20rem;
	height: 40rem;
	position: absolute;
	top: -8rem;
	left: -20rem;
	background: linear-gradient(
		180.57deg,
		#ffd166 0.49%,
		rgba(255, 130, 102, 0) 147.16%
	);
	border-radius: 3rem;
	transform: rotateZ(27deg);
	z-index: -1;
}

.teamCont {
	display: flex;
	justify-content: space-around;
}

.imgCont {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.img {
	margin: 1rem;
	width: 15rem;
	height: 15rem;
	background: #f7f8fa;
}

.name {
	font-size: 1.2rem;
	margin-bottom: 0;
}

.role {
	font-size: 0.8rem;
	color: #999;
}

.contact {
	margin-top: 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact .content {
	margin-right: 4rem;
	margin-left: 4rem;
}

.contactBtn {
	padding: 0.5rem 2rem;
	outline: none;
	border: none;
	color: #fff;
	background: linear-gradient(45deg, #ff5b5b 0%, #dba3db 100%);
	border-radius: 10px;
}

@media screen and (max-width: 768px) {
	.first {
		padding-top: 0;
	}
	.cont {
		margin: 7rem auto 1rem auto;
		width: 100%;
	}

	.heading {
		margin: 2rem;
		font-size: 2.5rem;
	}
	.ourCulture {
		margin: 2rem;
		font-size: 2rem;
		font-family: Avenir-Bold;
	}

	.subHeading {
		margin: 2rem;
		padding: 0;
		font-size: 2rem;
	}

	.content {
		margin: 2rem;
	}

	.valueCont {
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.aside {
		width: 100%;
	}
	.valueHead {
		width: 100%;
	}

	.valuesScrollOne {
		/* margin: 0 2rem; */
		width: 100%;
	}
	.valuesScroll {
		/* margin: 0 2rem; */
		width: 100%;
	}

	.values {
		width: 100%;
	}

	.value {
		margin: 0 2rem;
	}

	.imgCont {
		margin: 1rem;
		width: 50%;
	}

	.img {
		width: 100%;
		height: 10rem;
	}

	.contact {
		margin: 1rem 2rem;
		display: flex;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 4rem;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}

	.contact .content {
		margin-left: 1rem;
		margin-right: 1rem;
		text-align: justify !important;
	}

	.yellowBox {
		width: 25rem;
		height: 40rem;
		position: absolute;
		top: -92rem;
		left: -20rem;
		background: linear-gradient(
			180.57deg,
			#ffd166 0.49%,
			rgba(255, 130, 102, 0) 147.16%
		);
		border-radius: 3rem;
		transform: rotateZ(35deg);
		z-index: -1;
	}

	.pinkCircle {
		display: none;
	}

	.blueBox {
		width: 15rem;
		height: 25rem;
		position: absolute;
		bottom: 35rem;
		right: -10rem;

		background: linear-gradient(57.46deg, #5bceff 0.1%, #5b6cff 71.51%);
		transform: rotateZ(-25deg);
		border-radius: 3rem;
		z-index: -1;
	}
}
