.pop {
	border: 0 !important;
	box-shadow: 0 0 10px 0 #bfbfbf;
	margin-top: 0.6rem;
}

.popover {
	border: 1px solid #eee !important;
	box-shadow: 0 0 10px 0 #bfbfbf;
	padding: 1.6rem 1.7rem;
	width: 25rem;
}
.popover:focus {
	box-shadow: none !important;
}

.closeButton {
	background-color: transparent;
	border: none;
	top: 0.5rem !important;
	right: 0.5rem !important;
}

.btn {
	border: 1px solid red !important;
}

.container {
	/* width: 9rem; */
	/* height: 6rem; */
	padding: 0 0 0.6rem;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.header {
	padding-left: 0 !important;
}

.header span {
	color: #555;
	font-family: inter;
	font-size: 1rem;
	font-weight: 500;
}

.textIcon {
	display: flex;
	margin: 0 1rem;
	align-items: center;
	cursor: pointer;
}

.textIcon p {
	margin: 0;
	margin-left: 1rem;
	color: #555;
}

.footer {
	font-size: 0.8rem;
}

.footer p {
	margin-bottom: 0.6rem;
}

.textField {
	width: 100%;
	display: flex;
	border: 1px solid #bbb;
	/* padding: 5px 0; */
	align-items: center;
	border-radius: 5px;
}
.linkInput {
	flex: 1;
	border: 0;
	height: 25px;
	font-size: 0.95rem;
	text-indent: 10px;
	line-height: 25px;
	font-family: inter;
	font-style: normal;
	font-weight: 400;
	color: #312e2e;
}

.linkInput::selection {
	background: transparent;
}
.shareContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.shareIcon {
	margin-left: 0.5rem;
	margin-right: 1.5rem;
}
.shareText {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 1.09rem;
	line-height: 1.45rem;
	color: #312e2e;
	width: 95%;
}

.shareClose {
	position: absolute !important;
	top: 1.85rem;
	right: 1.3rem;
}
.shareSocialIcons {
	margin-top: 1.95rem;
	margin-bottom: 1.5rem;
}
.shareSocialCopy {
	margin-bottom: 0.2rem;
}
.copyButton {
	background: var(--text-dark);
	border: none;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
}

@media only screen and (max-width: 600px) {
	.textIcon p {
		display: none;
	}
	.textField {
		width: 80%;
	}
	.popover {
		border: 1px solid #eee !important;
		box-shadow: 0 0 10px 0 #bfbfbf;
		padding: 0.6rem 0.7rem;
		width: 100%;
	}
	.copyButton {
		height: 105%;
		border: 0;
		color: #ff5b5b;
		background-color: transparent;
		/* margin-left: -1.65rem;
		margin-top: -1px;
		margin-bottom: -1.3px; */
	}
}
