.inputCont {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 1rem;
	/* background-color: #121528; */
	border-radius: 6px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}

.inputCont[disabled] {
	pointer-events: none;
	opacity: 0.7;
}

.replyBox {
	width: 100%;
	margin: 0 0 10px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.replyCont {
	position: relative;
	width: 80%;
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.05);
	margin: 0.3rem 1rem 0.3rem 0.5rem;
	padding: 1rem 1rem 0.5rem 0.5rem;
}

.errorInfo {
	color: #aaa;
	font-size: 0.7rem;
	font-style: italic;
	line-height: 5px;
	padding: 0 5px;
}

.replyImgCont {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
}

.replyUser {
	margin: 0.125rem 0;
	font-size: 0.8rem;
	font-size: 0.625rem;
	color: #7f89be;
	font-weight: 400;
	font-style: normal;
	font-family: "Poppins";
}

.replyImg {
	margin: 0 1rem 0 0;
	max-width: 10rem;
}

.emoji {
	position: absolute;
	bottom: 4.4rem;
	cursor: pointer;
}

.secContainer {
	display: flex;
}
.replyText {
	max-height: 200px;
	width: 90%;
	overflow-y: auto;
	color: #d9def4;
	font-weight: 400;
	font-style: normal;
	font-size: 0.75rem;
	font-family: "Poppins";
}
.replyText::-webkit-scrollbar {
	display: none;
}

.inputBox {
	height: 3rem;
	color: #555;
	display: flex;
	margin: 10px 0.5rem;
	position: relative;
	flex: 1;
	align-items: center;
}

.input {
	width: 100%;
	padding: 0.7rem 1rem 0.7rem 0.3rem;
	border: 0;
	height: 100%;
	resize: none;
	background: transparent;
	border-radius: 100px;
	color: #d9def4;
	padding-left: 2rem;
	line-height: 24px;
	display: flex;
	align-items: center;
}

.input::placeholder {
	font-family: "Poppins";
	font-style: normal;
	font-size: 0.75rem;
	color: #7f89be;
}

.inputbox_breakline {
	border: 1px solid #2e2e48;
	width: 100%;
	margin-left: 0 !important;
	margin-top: 0px !important;
	margin-bottom: 0.5rem;
}

.input:disabled {
	opacity: 0.5;
}

.joinConv {
	flex: 1;
	min-height: 3rem;
	padding: 0.7rem 0;
	border: 0;
	color: #ff5b5b;
	background-color: white;
	border-radius: 6px;
	line-height: 0.7rem;
	box-shadow: 0px 2px 20px #00000026;
}

.metaTagsCont {
	position: relative;
	border-radius: 5px;
	background: rgba(255, 91, 91, 0.2);
	border: 1px solid #ff5b5b;
	margin: 0.3rem 1rem;
	padding: 0.5rem;
	display: flex;
	overflow: hidden;
	color: black;
}

.metaTagsCont:hover {
	color: black;
}

.metaContent {
	margin-right: 1rem;
}

.metaMedia {
	max-width: 8rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 0.5rem;
}
.metaMedia img {
	min-height: 60px;
	max-height: 8rem;
	max-width: 80%;
	object-fit: fill;
}

.metaContent {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.metaContent h2 {
	font-size: 1.1rem;

	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}
.metaContent p {
	font-size: 0.8rem;
	max-width: 70%;
	margin: 0;
}

@media only screen and (max-width: 768px) {
	.inputCont {
		padding: 0.3rem 0.3rem 0;
		margin-top: 0;
	}
	.secContainer {
		align-items: center;
	}
	.metaMedia {
		max-width: 6rem;
	}
	.metaMedia img {
		max-width: 6rem;
		max-height: 6rem;
	}

	.metaContent p {
		max-width: 100%;
		max-height: 50px;
	}
}

.modal1 {
	border-radius: 1rem;
}

.inputCon {
	margin: 1rem 0 0 0;
	display: flex;
}

.image {
	width: 100%;
}

.fileName {
	color: #888;
	margin-top: 1rem;
	font-size: 0.8rem;
	text-align: center;
	font-family: Roboto;
}

.darkm {
	background-color: #0d0f22;
}

.lgm {
	background-color: #fff;
}
