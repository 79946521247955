.breadcrumb {
	display: flex;
	padding: 0 1.2rem;
	margin: 2rem 0 1rem;
	justify-content: space-around;
	font-family: Roboto;
	font-size: 1.3rem;
}

.block {
	width: 50%;
	cursor: pointer;
	color: #999;
	display: flex;
	border-bottom: 2px solid #999;
	justify-content: center;
}
.icon {
	position: sticky;
	margin-bottom: -1.9em;
	margin-top: 0.7em;
	margin-left: 0.2em;
	z-index: 3;
	font-size: 1.3rem !important;
	/* text-align: center; */
}
.select {
	width: 50%;
	cursor: pointer;
	color: #ff5b5b;
	display: flex;
	border-bottom: 3px solid #ff5b5b;
	justify-content: center;
}

.status {
	width: 100%;
}

.user {
	width: 100%;
	margin-bottom: 2rem;
}

.resultDiv {
	margin: 0 10%;
	min-height: 30vh;
}

.details {
	margin: 0 0 2rem 0;
	padding: 0.5rem 0;
	height: 2rem;
	border: 0;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	outline: none !important;
	text-align: center;
	text-align-last: center;
	color: #555;
	font-size: 1rem;
	font-family: Roboto;
	-webkit-appearance: none;
	appearance: none;
}
.detailsBio {
	margin: 0 0 2rem 0;
	padding: 0.5rem 0;
	height: 5rem;
	border: 0;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	outline: none !important;
	text-align: center;
	text-align-last: center;
	color: #555;
	font-size: 1rem;
	font-family: Roboto;
	-webkit-appearance: none;
	appearance: none;
}
.detailsUrl {
	margin: 0 0 1rem 0;
	padding: 0.4rem 0;
	padding-left: 2.5rem;
	padding-right: 0rem;
	height: 2rem;
	border: 0;
	/* box-shadow:  0px 0px 4px 2px rgba(0, 0, 0, 0.05); */
	/* border-radius: 6px; */
	background-color: white;
	outline: none !important;
	text-align: left;
	text-align-last: left;
	color: #555;
	font-size: 0.9rem;
	font-family: Roboto;
	-webkit-appearance: none;
	appearance: none;
	overflow: hidden;
	text-overflow: ellipsis;
}
.border {
}
.label {
	width: 100%;
	text-align: center;
	color: #c2bdbd;
	font-size: 1rem;
	font-family: Roboto;
}
.labelOne {
	width: 100%;
	text-align: center;
	color: #888888;
	font-size: 1.4rem;
	font-family: Roboto;
}
